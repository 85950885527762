import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Button, Card, CardBody } from "@material-tailwind/react";
import { FaInfoCircle } from "react-icons/fa";
import { MdOutlinePayments } from "react-icons/md";
import { FaCar } from "react-icons/fa";
import { TbMapShare } from "react-icons/tb";
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import Spinner from '../components/Spinner'; // Ensure the spinner component is correctly imported
import { RiSendPlaneFill } from "react-icons/ri";
import { GrPowerReset } from "react-icons/gr";
import { useLocation, useHistory } from 'react-router-dom';


//const API_PATH_PREFIX = 'https://new.cajuu.app/api/';
//const API_PATH_PREFIX = 'http://localhost:8000/api/';

const initialFormData = {
    orderId: '',
    loadboard: '',
    driver: '',
    partnerCompany: '',
    shipper: '',
    paymentRate: '',
    paymentType: '',
    paymentMethod: '',
    citypickup: '',
    Statepickup: '',
    zipcodepickup: '',
    contactNamepickup: '',
    PickupBusinessName: '',
    phonepickup: '',
    emailpickup: '',
    addressdelivery: '',
    citydelivery: '',
    Statedelivery: '',
    zipcodedelivery: '',
    contactNamedelivery: '',
    DeliveryBusinessName: '',
    phonedelivery: '',
    emaildelivery: '',
    paperwork: '',
    gatePass: '', // Novo campo adicionado
    cars: [{
        vin: '',
        make: '',
        model: '',
        year: '',
        carType: '',
        isOperable: ''
    }]
};

const FormSubmitHandler = () => {
        const [formData, setFormData] = useState(initialFormData);
        const [drivers, setDrivers] = useState([]);
        const [partnerCompanies, setPartnerCompanies] = useState([]);
        const [shippers, setShippers] = useState([]);
        const [carTypes, setCarTypes] = useState([]);
        const {
            vehicleId
        } = useParams();
        const [isInspectionCompleted, setIsInspectionCompleted] = useState(false);
        const [loading, setLoading] = useState(false); // Loading state
        const [isOpen, setIsOpen] = useState({
            card1: false,
            card2: false,
            card3: false,
            card0: false,
            card4: false,
        });
        const history = useHistory();
        const location = useLocation();

        const [quote, setQuote] = useState(null);
/*
        useEffect(() => {
            const userToken = localStorage.getItem('userToken');

            if(!userToken){
                Swal.fire({
                    title: 'Hey, you are not logged in!',
                    text: 'Please sign in or sign up to proceed with creating a load.',
                    icon: 'warning',
                    showConfirmButton: true,
                    timer: 10000,
                    timerProgressBar: true,
                });

                history.push('/Login');
            }
        }, []);
*/
        useEffect(() => {
            const fetchQuote = async () => {
                //const userToken = localStorage.getItem('userToken');
                const idQuote = new URLSearchParams(location.search).get('id');

                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_PREFIX}/quote-eligible/${idQuote}`, {
 /*                           headers: {
                                "Screen-Name": "/Quotes",
                                "Authorization": `Bearer ${userToken}`
                            }
*/                        });
                  console.log(response.data.data);
                    const quoteData = response.data.data; // Ajuste conforme a estrutura da sua resposta
                    setQuote(quoteData); // Ajuste conforme a estrutura da sua resposta
                    setFormData(mapQuoteToFormData(quoteData));
                    //console.log("quote");
                    //console.log(quote);
                } catch (error) {
                    console.error("There was an error requesting the data:", error);

                    //console.error("There was an error submitting the form:", error);

                    let errorMessage = 'The requested quote doesn\'t exist or a Load Transport has already been created.';
                    if (error.response && error.response.data) {
                        errorMessage = `${error.response.data.message} - ${error.response.data.error}`;
                    }

                    Swal.fire({
                        title: 'Error!',
                        text: errorMessage,
                        icon: 'error',
                        timer: 4000,
                        timerProgressBar: true,
                    });

                    history.push('/Dashboard');

                }
            };

            fetchQuote();

        }, [location.search]);

        const mapQuoteToFormData = (quote) => {
            return {
                orderId: 'J316-' + quote.id,
                loadboard: 'Cajuu',
                partnerCompany: quote.partner_company_id,
                //shipper: quote.partner_company_id,
                paymentRate: quote.payment_rate,
                citypickup: quote.pickup_city,
                Statepickup: quote.pickup_state,
                zipcodepickup: quote.pickup_zipcode,
                citydelivery: quote.delivery_city,
                Statedelivery: quote.delivery_state,
                zipcodedelivery: quote.delivery_zipcode,
                cars: quote.quote_vehicles.map(item => ({
                    //vin: item.vin,
                    make: item.make,
                    model: item.model,
                    year: item.year,
                    //carType: item.car_type_id,
                    isOperable: item.operable ? 'Yes' : 'No'
                }))
            };
        };



        const toggleCard = (cardKey) => {
            setIsOpen(prev => ({
                ...prev,
                [cardKey]: !prev[cardKey]
            }));
        };

        // const fetchCarTypes = useCallback(async () => {
        //     try {
        //         setLoading(true); // Start loading
        //         const userToken = localStorage.getItem('userToken');

        //         const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/cartype`, {
        //             headers: {
        //                 "Screen-Name": "/NewLoad",
        //                 "Authorization": `Bearer ${userToken}`
        //             }
        //         });
        //         setCarTypes(response.data.data);
        //     } catch (error) {
        //         console.error('Error fetching car types:', error);
        //     } finally {
        //         setLoading(false); // End loading
        //     }
        // }, []);

        // useEffect(() => {
        //     fetchCarTypes();
        // }, [fetchCarTypes]);

        // const fetchDriver = useCallback(async () => {
        //     try {
        //         const userToken = localStorage.getItem('userToken');

        //         const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/driver`, {
        //             headers: {
        //                 "Screen-Name": "/NewLoad",
        //                 "Authorization": `Bearer ${userToken}`
        //             }
        //         });
        //         console.log("Drivers:", response.data.data);
        //         setDriver(response.data.data);
        //     } catch (error) {
        //         console.error("Erro ao buscar drivers", error);
        //     }
        // }, []);

        // const fetchPartnerCompanies = useCallback(async () => {
        //     try {
        //         const userToken = localStorage.getItem('userToken');

        //         const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/partnercompany`, {
        //             headers: {
        //                 "Screen-Name": "/NewLoad",
        //                 "Authorization": `Bearer ${userToken}`
        //             }
        //         });

        //         setPartnerCompanies(response.data.data);
        //     } catch (error) {
        //         console.error("Erro ao buscar partner companies", error);
        //     }
        // }, []);

        // const fetchShippers = useCallback(async () => {
        //     try {
        //         const userToken = localStorage.getItem('userToken');

        //         const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/shipper`, {
        //             headers: {
        //                 "Screen-Name": "/NewLoad",
        //                 "Authorization": `Bearer ${userToken}`
        //             }
        //         });
        //         setShippers(response.data.data);
        //     } catch (error) {
        //         console.error("Erro ao buscar shippers", error);
        //     }
        // }, []);

        // useEffect(() => {
        //     fetchDriver();
        //     fetchPartnerCompanies();
        //     fetchShippers();
        // }, [fetchDriver, fetchPartnerCompanies, fetchShippers]);

        const fetchSelectOptions = useCallback(async () => {
            try {
                setLoading(true); // Start loading
                const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/quote-select-options`);

                // Assuming the response structure contains the keys as mentioned in the previous API response
                const {
                    car_types,
                    //drivers,
                    partner_companies
                    //shippers
                } = response.data;

                // Update state with the fetched data
                setCarTypes(car_types);
                //setDrivers(drivers);
                setPartnerCompanies(partner_companies);
                //setShippers(shippers);

            } catch (error) {
                console.error('Error fetching select options:', error);
            } finally {
                setLoading(false); // End loading
            }
        }, []);

        useEffect(() => {
            fetchSelectOptions();
        }, [fetchSelectOptions]);

        const handleChange = (e) => {
            const {
                name,
                value
            } = e.target;
            setFormData({
                ...formData,
                [name]: value
            });
        };



        const addCar = () => {
            if (formData.cars.length < 9) {
                setFormData({
                    ...formData,
                    cars: [...formData.cars, {}]
                });
            } else {
                // Opcional: Você pode adicionar uma lógica aqui para informar o usuário
                // que o limite de carros foi atingido, por exemplo, usando um alert.
                alert("You have reached the limit of 9 cars.");
            }
        };

        const deleteCar = (index) => {
            const updatedCars = formData.cars.filter((_, i) => i !== index);
            setFormData({
                ...formData,
                cars: updatedCars
            });
        };


        const handleCarChange = (carIndex, e) => {
            const updatedCars = formData.cars.map((car, index) => {
                if (index === carIndex) {
                    return {
                        ...car,
                        [e.target.name]: e.target.value
                    };
                }
                return car;
            });
            setFormData({
                ...formData,
                cars: updatedCars
            });
        };

        const removeLastCar = () => {
            setFormData({
                ...formData,
                cars: formData.cars.slice(0, -8)
            });
        };



        const handleSubmit = async (e) => {
            e.preventDefault();
            setLoading(true); // Start loading


            console.log("Dados do Formulário antes do envio:", formData);

            const idQuote = new URLSearchParams(location.search).get('id');

            const payload = {
                quote_id: idQuote,
                driver_id: formData.driver === "" ? null : parseInt(formData.driver),
                partner_company_id: parseInt(formData.partnerCompany),
                //shipper_id: parseInt(formData.shipper),
                loadboard: formData.loadboard,
                order_id: formData.orderId,
                pickup_address: formData.addresspickup,
                pickup_city: formData.citypickup,
                pickup_state: formData.Statepickup,
                pickup_zipcode: formData.zipcodepickup,
                pickup_customer_name: formData.contactNamepickup,
                pickup_customer_phone: formData.phonepickup,
                pickup_customer_email: formData.emailpickup,
                pickup_business_name: formData.PickupBusinessName,
                delivery_address: formData.addressdelivery,
                delivery_city: formData.citydelivery,
                delivery_state: formData.Statedelivery,
                delivery_zipcode: formData.zipcodedelivery,
                delivery_customer_name: formData.contactNamedelivery,
                delivery_customer_phone: formData.phonedelivery,
                delivery_customer_email: formData.emaildelivery,
                delivery_business_name: formData.DeliveryBusinessName,
                payment_rate: parseFloat(formData.paymentRate),
                payment_method: formData.paymentMethod,
                payment_type: formData.paymentType,
                //dispatch_date: "2024-02-08",
                expected_pickup_date: formData.pickupDateEstimated,
                expected_dropoff_date: formData.deliveryDateEstimated,
                load_items: formData.cars.map(car => ({
                    car_type_id: car.carType ? parseInt(car.carType) : null,
                    model: car.model,
                    year: car.year,
                    vin: car.vin,
                    make: car.make,
                    buyer_number: car.buyerNumber,
                    lot_number: car.lotNumber,
                    is_operable: car.isOperable === 'true',
                    additional_info: car.additionalInfo || ""
                }))
            };

            try {
                const userToken = localStorage.getItem('userToken');

                const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/loadtransport-store-from-quote`, payload);
                if (response.status === 200) {
                    Swal.fire({
                        title: 'Sucesso!',
                        text: 'Form successfully submitted!',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 4000,
                        timerProgressBar: true,
                    });

                    localStorage.removeItem('quoteId');
                    localStorage.removeItem('quoteTimestamp');

                    history.push('/Dashboard');
                }
            } catch (error) {
                console.error("There was an error submitting the form:", error);

                let errorMessage = 'Request failed';
                if (error.response && error.response.data) {
                    errorMessage = `${error.response.data.message} - ${error.response.data.error}`;
                }

                Swal.fire({
                    title: 'Error!',
                    text: errorMessage,
                    icon: 'error',
                    // timer: 4000,
                    // timerProgressBar: true,
                });
            } finally {
                setLoading(false); // End loading
            }
        };


        useEffect(() => {
            const fetchVehicleData = async () => {
                try {
                    const userToken = localStorage.getItem('userToken');
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_PREFIX}/vehicle/${vehicleId}`, {
                            headers: {
                                "Screen-Name": "/NewLoad",
                                "Authorization": `Bearer ${userToken}`
                            }
                        });
                    const vehicleData = response.data;
                    setFormData({
                        ...formData,
                        // Atualize o formData aqui com os dados do veículo
                    });
                    setIsInspectionCompleted(vehicleData.inspectionStatus === 'completed');
                } catch (error) {
                    console.error("Erro ao buscar dados do veículo:", error);
                }
            };

            if (vehicleId) fetchVehicleData();
        }, [vehicleId]);


        const fetchVehicleData = async (index, vin) => {
            try {
                setLoading(true);
                const userToken = localStorage.getItem('userToken');
                const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/vin/${vin}`, {
                    headers: {
                        "Screen-Name": "/Api_Vin",
                        "Authorization": `Bearer ${userToken}`
                    }
                });
                const {
                    year,
                    make,
                    model,
                    specs
                } = response.data;
                const newCars = formData.cars.map((car, idx) => {
                    if (idx === index) {
                        return {
                            ...car,
                            vin,
                            year,
                            make,
                            model,
                            carType: specs.vehicle_type
                        };
                    }
                    return car;
                });
                setFormData({
                    ...formData,
                    cars: newCars
                });
            } catch (error) {
                console.error('Error fetching vehicle data:', error);
            } finally {
                setLoading(false);
            }
        };

        // Ajuste no handleVinChange para evitar chamadas desnecessárias
        const handleVinChange = (index, event) => {
            const {
                name,
                value
            } = event.target;
            if (value.length === 17) {
                fetchVehicleData(index, value);
            }
            handleCarChange(index, event);
        };

        const handleFileChange = (e) => {
            const file = e.target.files[0];
            if (file && file.type === 'application/pdf') {
                setFormData({
                    ...formData,
                    gatePass: file
                });
            } else {
                alert('Please upload a valid PDF file.');
            }
        };

        return (
        <div className="px-4">

            <h2 className="text-2xl font-bold mb-2 mt-10"></h2>
            <a href="#" className="flex items-center justify-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                <img className="w-12 h-12" src="./cajuu.png" alt="logo" />
            </a>
            {loading ? (
                <Spinner />
            ) : (
                <form className="flex flex-col" onSubmit={handleSubmit}>
                    {/* COMPONENTE OR GENERALINFORMATION */}
                    <Card className="mt-4 mx-auto  bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-hidden">
                        <div className="flex justify-between items-center p-6 cursor-pointer" onClick={() => toggleCard('card3')}>
                            <FaInfoCircle className="w-6 h-6 text-blue-500" style={{ color: 'orange' }} />
                            <h2 className="text-lg font-semibold text-gray-900 dark:text-white">General Information</h2>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={`transition-transform duration-300 ${isOpen ? 'rotate-180' : 'rotate-0'}`}>
                                <path d="M18 15l-6-6-6 6" />
                            </svg>
                        </div>
                        {isOpen.card3 && ( // Renderiza o formulário somente se isOpen for verdadeiro
                            <div className="flex flex-col w-full p-4 bg-gray-50 dark:bg-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors duration-200 rounded-lg shadow-lg">
                                <div className="flex flex-wrap -mx-2">
                                    <div className="flex flex-col px-2 w-full md:w-1/2 lg:w-1/3">
                                        <label htmlFor="orderId" className="text-sm font-medium text-gray-900 dark:text-gray-300 mb-1">
                                            Order Id:
                                        </label>
                                        <input
                                            id="orderId"
                                            name="orderId"
                                            required
                                            type="text"
                                            /* value={formData.orderId} */
                                            value={quote ? 'J316-' + quote.id : ''}
                                            disabled
                                            onChange={handleChange}
                                            className="p-2.5 w-full text-sm text-gray-900 bg-white border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                            placeholder="Enter Order Id"
                                        />
                                    </div>

                                    <div className="flex flex-col px-2 w-full md:w-1/2 lg:w-1/3">
                                        <label htmlFor="loadboard" className="text-sm font-medium text-gray-900 dark:text-gray-300 mb-1">
                                            Loadboard:
                                        </label>
                                        <select
                                            name="loadboard"
                                            value={formData.loadboard}
                                            disabled
                                            onChange={handleChange}
                                            className="p-2.5 w-full text-sm bg-white border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                        >
                                            <option value="" className="italic text-gray-400">Select LoadBoard</option>
                                            <option value="Cajuu" selected>Cajuu</option>
                                            <option value="Central dispatch">Central dispatch</option>
                                            <option value="Super dispatch">Super dispatch</option>
                                            <option value="Ship.cars">Ship.cars</option>
                                            <option value="Autosled">Autosled</option>
                                            <option value="Acertus">Acertus</option>
                                            <option value="Runbugy">Runbugy</option>
                                            <option value="Ready">Ready</option>
                                            <option value="Carsarrive">Carsarrive</option>
                                            <option value="United">United</option>
                                            <option value="ACV Transportation">ACV Transportation</option>
                                            <option value="Uship">Uship</option>
                                            <option value="Email">Email</option>
                                        </select>
                                    </div>

                                    {/* <div className="flex flex-col px-2 w-full md:w-1/2 lg:w-1/3">
                                        <label htmlFor="paperwork" className="text-sm font-medium text-gray-900 dark:text-gray-300 mb-1">
                                            Paperwork:
                                        </label>
                                        <select
                                            name="paperwork"
                                            value={formData.paperwork}
                                            onChange={handleChange}
                                            className="p-2.5 w-full text-sm bg-white border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                        >
                                            <option value="" className="italic text-gray-400">Select Yes or No</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div> */}

{/* Campo Removido por solicitação do Allan em 21/10/2024 */}
{/*}
                                    <div className="flex flex-col px-2 w-full md:w-1/2 lg:w-1/3">
                                        <label htmlFor="partnerCompany" className="text-sm font-medium text-gray-900 dark:text-gray-300 mb-1">
                                            Carrier Company:
                                        </label>
                                        <select
                                            name="partnerCompany"
                                            required
                                            value={formData.partnerCompany}
                                            disabled
                                            onChange={handleChange}
                                            className="form-select p-2.5 w-full text-sm bg-white border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                        >
                                            <option value="" className="italic text-gray-400">Select the company</option>
                                            {partnerCompanies?.map((company) => (
                                                <option key={company.id} value={company.id}>{company.partner_company_name}</option>
                                            ))}
                                        </select>
                                    </div>
*/}

{/*
                                    <div className="flex flex-col px-2 w-full md:w-1/2 lg:w-1/3">
                                        <label htmlFor="shipper" className="text-sm font-medium text-gray-900 dark:text-gray-300 mb-1">
                                            Shipper:
                                        </label>
                                        <select
                                            name="shipper"
                                            value={formData.shipper}
                                            disabled
                                            onChange={handleChange}
                                            className="form-select p-2.5 w-full text-sm bg-white border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                        >
                                            <option value="" className="italic text-gray-400">Select the Shipper</option>
                                            {shippers?.map((shipper) => (
                                                <option key={shipper.id} value={shipper.id}>{shipper.shipper_name}</option>
                                            ))}
                                        </select>
                                    </div>
*/}
                                    {/* <div className="flex flex-col px-2 w-full md:w-1/2 lg:w-1/3">
                                        <label htmlFor="gatePass" className="text-sm font-medium text-gray-900 dark:text-gray-300 mb-1">
                                            Gate Pass:
                                        </label>
                                        <input
                                            id="gatePass"
                                            name="gatePass"
                                            type="file"
                                            accept=".pdf"
                                            onChange={handleFileChange}
                                            className="p-2.5 w-full text-sm text-gray-900 bg-white border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                        />
                                    </div> */}
                                </div>
                            </div>
                        )}
                    </Card>
                    {/* COMPONENTE OR PAYMENTINFO */}
                    <Card className="mt-4 mx-auto  bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-hidden">
                        <div className="flex justify-between items-center p-6 cursor-pointer" onClick={() => toggleCard('card2')}>
                            <MdOutlinePayments className="w-6 h-6 text-blue-500" style={{ color: 'orange' }} />
                            <h2 className="text-lg font-semibold text-gray-900 dark:text-white">Payment Info</h2>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={`transition-transform duration-300 ${isOpen ? 'rotate-180' : 'rotate-0'}`}>
                                <path d="M18 15l-6-6-6 6" />
                            </svg>
                        </div>
                        {isOpen.card2 && ( // Renderiza o formulário somente se isOpen for verdadeiro
                            <div className="flex flex-wrap w-full p-4 bg-gray-50 dark:bg-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors duration-200 rounded-lg shadow-lg">
                                <div className="px-2 w-full lg:w-1/3">
                                    <label htmlFor="paymentRate" className="text-sm font-medium text-gray-900 dark:text-gray-300 mb-1">
                                        Payment Rate:
                                    </label>
                                    <input
                                        required
                                        id="paymentRate"
                                        name="paymentRate"
                                        type="number"
                                        value={formData.paymentRate}
                                        disabled
                                        onChange={handleChange}
                                        className="block p-2.5 w-full text-sm text-gray-900 bg-white border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                        placeholder="Enter Payment"
                                    />
                                </div>

                                <div className="px-2 w-full lg:w-1/3">
                                    <label htmlFor="paymentType" className="text-sm font-medium text-gray-900 dark:text-gray-300 mb-1">
                                        Payment Type:
                                    </label>
                                    <select
                                        id="paymentType"
                                        name="paymentType"
                                        value={formData.paymentType}
                                        onChange={handleChange}
                                        className="block p-2.5 w-full text-sm bg-white border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                    >
                                        <option value="" className="italic text-gray-400">Select payment type</option>
                                        <option value="COD">COD</option>
                                        <option value="COP">COP</option>
                                    </select>
                                </div>

                                <div className="px-2 w-full lg:w-1/3">
                                    <label htmlFor="paymentMethod" className="text-sm font-medium text-gray-900 dark:text-gray-300 mb-1">
                                        Payment Method:
                                    </label>
                                    <select
                                        id="paymentMethod"
                                        name="paymentMethod"
                                        value={formData.paymentMethod}
                                        onChange={handleChange}
                                        className="block p-2.5 w-full text-sm bg-white border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                    >
                                        <option value="" className="italic text-gray-400">Select payment method</option>
                                        {/* <option value="ACH">ACH</option> */}
                                        <option value="Check">Check</option>
                                        <option value="Zelle">Zelle</option>
                                        <option value="Venmo">Venmo</option>
                                        <option value="Cashapp">Cashapp</option>
                                        {/* <option value="Uship">Uship</option> */}
                                        <option value="Cash">Cash</option>
                                        {/* <option value="PayPal">PayPal</option> */}
                                        <option value="Credit card">Credit card</option>
                                        <option value="Debit">Debit</option>
                                        {/*<option value="Comcheck">Comcheck</option> */}
                                        {/*<option value="Echeck">Echeck</option> */}
                                        {/*<option value="Eletronic Check">Eletronic Check</option> */}
                                    </select>
                                </div>
                            </div>
                        )}
                    </Card>
                    {/* COMPONENTE OR LOADITEMINFO */}
                    <Card className="mt-4 mx-auto  bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-hidden">
                        <div className="flex justify-between items-center p-6 cursor-pointer" onClick={() => toggleCard('card1')}>
                            <FaCar className='w-8 h-8' style={{ color: 'orange' }} />
                            <h2 className="text-lg font-semibold text-gray-900 dark:text-white"

                            >Load Items Info</h2>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={`transition-transform duration-300 ${isOpen.card1 ? 'rotate-180' : 'rotate-0'}`}>
                                <path d="M18 15l-6-6-6 6" />
                            </svg>
                        </div>
                        {isOpen.card1 && (
                            <div className="bg-white dark:bg-gray-800 overflow-hidden">
                                {formData.cars.map((car, index) => (
                                    <div key={index} className="mt-3 p-4 bg-gray-50 dark:bg-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors duration-200 rounded-lg shadow-lg">
                                        <div className="flex flex-wrap w-full p-4 bg-gray-50 dark:bg-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors duration-200 rounded-lg shadow-lg">
                                            <div className="px-2 w-full lg:w-1/3">
                                                <label htmlFor="vin" className="flex text-sm font-medium leading-6 text-gray-900"

                                                >
                                                    Vin:
                                                </label>
                                                <input
                                                    name="vin"
                                                    value={car.vin}
                                                    onChange={(e) => handleVinChange(index, e)}
                                                    placeholder="Vin Number Car"
                                                    className="block p-2.5 w-full text-sm bg-white border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                                />
                                            </div>

                                            <div className="px-2 w-full lg:w-1/3">
                                                <label htmlFor="year" className="flex text-sm font-medium leading-6 text-gray-900"

                                                >
                                                    Year:
                                                </label>
                                                <input
                                                    name="year"
                                                    value={car.year || ''}
                                                    onChange={(e) => handleCarChange(index, e)}
                                                    placeholder="Year Car"
                                                    required
                                                    type="number"
                                                    className="block p-2.5 w-full text-sm bg-white border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                                />
                                            </div>


                                            <div className="px-2 w-full lg:w-1/3">
                                                <label htmlFor="make" className="flex text-sm font-medium leading-6 text-gray-900"

                                                >
                                                    Make:
                                                </label>
                                                <input
                                                    name="make"
                                                    value={car.make || ''}
                                                    onChange={(e) => handleCarChange(index, e)}
                                                    placeholder="Make Car"
                                                    required
                                                    type="text"
                                                    className="block p-2.5 w-full text-sm bg-white border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                                />
                                            </div>

                                            <div className="px-2 w-full lg:w-1/3">
                                                <label htmlFor="model" className="flex text-sm font-medium leading-6 text-gray-900"

                                                >
                                                    Model:
                                                </label>
                                                <input
                                                    name="model"
                                                    value={car.model || ''}
                                                    onChange={(e) => handleCarChange(index, e)}
                                                    placeholder="Model Car"
                                                    required
                                                    type="text"
                                                    className="block p-2.5 w-full text-sm bg-white border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                                />
                                            </div>

                                            <div className="px-2 w-full lg:w-1/3">
                                                <label htmlFor="model" className="flex text-sm font-medium leading-6 text-gray-900"

                                                >
                                                    Lot N. :
                                                </label>
                                                <input
                                                    name="lotNumber"
                                                    value={car.lotNumber || ''}
                                                    onChange={(e) => handleCarChange(index, e)}
                                                    placeholder="Lot Number"
                                                    type="text"
                                                    className="block p-2.5 w-full text-sm bg-white border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                                />
                                            </div>

                                            <div className="px-2 w-full lg:w-1/3">
                                                <label htmlFor="model" className="flex text-sm font-medium leading-6 text-gray-900"

                                                >
                                                    Buyer N. :
                                                </label>
                                                <input
                                                    name="buyerNumber"
                                                    value={car.buyerNumber || ''}
                                                    onChange={(e) => handleCarChange(index, e)}
                                                    placeholder="Buyer Number"
                                                    type="text"
                                                    className="block p-2.5 w-full text-sm bg-white border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                                />
                                            </div>

                                            <div className="px-2 w-full lg:w-1/3">
                                                <label htmlFor="carType" className="flex text-sm font-medium leading-6 text-gray-900"

                                                >
                                                    Car Type:
                                                </label>
                                                <select
                                                    name="carType"
                                                    value={car.carType || ''}
                                                    onChange={(e) => handleCarChange(index, e)}
                                                    required
                                                    className="block p-2.5 w-full text-sm bg-white border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                                >
                                                    <option value="" disabled>Select Car Type</option>
                                                    {carTypes.map((cartype) => (
                                                        <option key={cartype.id} value={cartype.id}>
                                                            {cartype.type}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="px-2 w-full lg:w-1/3">
                                                <label htmlFor="isOperable" className="flex text-sm font-medium leading-6 text-gray-900"

                                                >
                                                    Is It Operable:
                                                </label>
                                                <select
                                                    name="isOperable"
                                                    value={car.isOperable || ''}
                                                    onChange={(e) => handleCarChange(index, e)}
                                                    required
                                                    className="block p-2.5 w-full text-sm bg-white border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                                >
                                                    <option value="">Select Is Operable</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="mt-4 flex justify-end">
                                            {formData.cars.length > 1 && (
                                                <button onClick={() => deleteCar(index)} className="flex items-center justify-center p-2 text-red-600 hover:text-red-800 dark:hover:text-red-400 transition-colors duration-200">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                        <path d="M3 6h18M9 9v10M15 9v10M10 3h4a1 1 0 011 1v3H9V4a1 1 0 011-1zM6 6h12v14a1 1 0 01-1 1H7a1 1 0 01-1-1V6z" />
                                                    </svg>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                ))}
                                {formData.cars.length >= 9 ? (
                                    <Button onClick={removeLastCar} type="button" className="mx-2 mr-2" style={{ backgroundColor: 'red', marginTop: '1rem' }}>
                                        Remove Last Car
                                    </Button>
                                ) : (
                                    <Button
                                        onClick={addCar}
                                        type="button"
                                        className="flex items-center justify-center mx-2 mr-2 py-2 px-4 rounded text-white font-bold mt-4 mb-4"
                                        style={{ background: 'orange' }}
                                    >
                                        Add Car
                                    </Button>
                                )}
                            </div>
                        )}
                    </Card>

                    <div className="flex flex-wrap -mx-3 mt-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            {/* COMPONENTE OR PICKUP */}
                            <Card className="mt-4 mx-auto max-w-4xl bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-hidden">
                                <div className="flex justify-between items-center p-6 cursor-pointer" onClick={() => toggleCard('card0')}>
                                    <TbMapShare className='w-6 h-6' style={{ color: 'orange' }} />
                                    <h2 className="text-lg font-semibold text-gray-900 dark:text-white">Pickup Info</h2>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={`transition-transform duration-300 ${isOpen ? 'rotate-180' : 'rotate-0'}`}>
                                        <path d="M18 15l-6-6-6 6" />
                                    </svg>
                                </div>
                                {isOpen.card0 && (
                                    <div className="bg-white dark:bg-gray-800 overflow-hidden">
                                        <div className="mt-3 p-4 bg-gray-50 dark:bg-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors duration-200 rounded-lg shadow-lg">
                                            <div className="flex flex-wrap w-full p-4 bg-gray-50 dark:bg-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors duration-200 rounded-lg shadow-lg">
                                                <div className="px-2 w-full">
                                                    <label htmlFor="PickupBusinessName" className="text-sm font-medium text-gray-900 dark:text-gray-300 mb-1">
                                                        Contact Company:
                                                    </label>
                                                    <input
                                                        id="PickupBusinessName"
                                                        name="PickupBusinessName"
                                                        type="text"
                                                        value={formData.PickupBusinessName}
                                                        onChange={(e) => setFormData({ ...formData, PickupBusinessName: e.target.value })}
                                                        className="block p-2.5 w-full text-sm text-gray-900 bg-white border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                                        placeholder="Enter Contact Company"
                                                    />
                                                </div>

                                                <div className="px-2 w-full lg:w-1/3">
                                                    <label htmlFor="contactNamepickup" className="text-sm font-medium text-gray-900 dark:text-gray-300 mb-1">
                                                        Contact Name:
                                                    </label>
                                                    <input
                                                        id="contactName"
                                                        name="contactName"
                                                        type="text"
                                                        value={formData.contactNamepickup}
                                                        onChange={(e) => setFormData({ ...formData, contactNamepickup: e.target.value })}
                                                        className="block p-2.5 w-full text-sm text-gray-900 bg-white border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                                        placeholder="Enter Contact Name"
                                                    />
                                                </div>

                                                <div className="px-2 w-full lg:w-1/3">
                                                    <label htmlFor="pickupDateEstimated" className="text-sm font-medium text-gray-900 dark:text-gray-300 mb-1">
                                                        Pickup Date Estimated:
                                                    </label>
                                                    <input
                                                        id="pickupDateEstimated"
                                                        name="pickupDateEstimated"
                                                        type="date"
                                                        value={formData.pickupDateEstimated}
                                                        className="block p-2.5 w-full text-sm text-gray-900 bg-white border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                                        placeholder="Enter Contact Name"
                                                    />
                                                </div>

                                                <div className="px-2 w-full lg:w-1/2">
                                                    <label htmlFor="emailpickup" className="text-sm font-medium text-gray-900 dark:text-gray-300 mb-1">
                                                        Email:
                                                    </label>
                                                    <input
                                                        type="emailpickup"
                                                        name="emailpickup"
                                                        id="email"
                                                        value={formData.emailpickup || ''}
                                                        onChange={handleChange}
                                                        className="block p-2.5 w-full text-sm text-gray-900 bg-white border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                                        placeholder="Cajuu@email.com"
                                                    />
                                                </div>
                                                <div className="px-2 w-full lg:w-1/3">
                                                    <label htmlFor="phonepickup" className="text-sm font-medium text-gray-900 dark:text-gray-300 mb-1">
                                                        Phone:
                                                    </label>
                                                    <input
                                                        type="tel"
                                                        name="phonepickup"
                                                        id="phonepickup"
                                                        value={formData.phonepickup || ''}
                                                        onChange={handleChange}
                                                        className="block p-2.5 w-full text-sm text-gray-900 bg-white border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                                        placeholder="123-456-7890"
                                                    />
                                                </div>

                                                {/* <div className="px-2 w-full lg:w-1/3">
                                                    <label htmlFor="NotesPickup" className="text-sm font-medium text-gray-900 dark:text-gray-300 mb-1">
                                                        Notes:
                                                    </label>
                                                    <textarea
                                                        id="NotesPickup"
                                                        className="TextNote form-control w-full"
                                                        style={{ backgroundColor: 'azure', borderColor: 'gray', borderRadius: '1rem' }}

                                                        rows="5"
                                                        placeholder="   Enter your observations here..."
                                                    ></textarea>
                                                </div> */}

                                                {/* Expanded Notes section */}
                                                <div className="px-2 w-full">
                                                    <label htmlFor="NotesPickup" className="text-sm font-medium text-gray-900 dark:text-gray-300 mb-1">
                                                        Notes:
                                                    </label>
                                                    <textarea
                                                        id="NotesPickup"
                                                        className="TextNote form-control w-full"
                                                        style={{ backgroundColor: 'azure', borderColor: 'gray', borderRadius: '1rem' }}
                                                        rows="5"
                                                        placeholder="   Enter your observations here..."
                                                    ></textarea>
                                                </div>


                                                <div className="px-2 w-full">
                                                    <label htmlFor="addresspickup" className="text-sm font-medium text-gray-900 dark:text-gray-300 mb-1">
                                                        Address:
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="addresspickup"
                                                        id="addresspickup"
                                                        required
                                                        value={formData.addresspickup || ''}
                                                        onChange={handleChange}
                                                        className="block p-2.5 w-full text-sm text-gray-900 bg-white border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                                        placeholder="Enter the Address"
                                                    />
                                                </div>

                                                <div className="px-2 w-full lg:w-1/3">
                                                    <label htmlFor="citypickup" className="text-sm font-medium text-gray-900 dark:text-gray-300 mb-1">
                                                        City:
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="citypickup"
                                                        id="citypickup"
                                                        required
                                                        value={formData.citypickup || ''}
                                                        onChange={handleChange}
                                                        className="block p-2.5 w-full text-sm text-gray-900 bg-white border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                                        placeholder="Enter the Address"
                                                    />
                                                </div>

                                                <div className="px-2 w-full lg:w-1/3">
                                                    <label htmlFor="Statepickup" className="text-sm font-medium text-gray-900 dark:text-gray-300 mb-1">
                                                        State:
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="Statepickup"
                                                        id="Statepickup"
                                                        required
                                                        value={formData.Statepickup || ''}
                                                        onChange={handleChange}
                                                        className="block p-2.5 w-full text-sm text-gray-900 bg-white border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                                        placeholder="Enter the Address"
                                                    />
                                                </div>

                                                <div className="px-2 w-full lg:w-1/3">
                                                    <label htmlFor="zipcodepickup" className="text-sm font-medium text-gray-900 dark:text-gray-300 mb-1">
                                                        Zipcode:
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="zipcodepickup"
                                                        id="zipcodepickup"
                                                        required
                                                        value={formData.zipcodepickup || ''}
                                                        onChange={handleChange}
                                                        className="block p-2.5 w-full text-sm text-gray-900 bg-white border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                                        placeholder="Enter the Address"
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Card>
                        </div>

                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            {/* COMPONENTE OR DELIVERY */}
                            <Card className="mt-4 mx-auto max-w-4xl bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-hidden">
                                <div className="flex justify-between items-center p-6 cursor-pointer" onClick={() => toggleCard('card4')}>
                                    <TbMapShare className='w-6 h-6' style={{ color: 'orange' }} />
                                    <h2 className="text-lg font-semibold text-gray-900 dark:text-white">Delivery Info</h2>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={`transition-transform duration-300 ${isOpen ? 'rotate-180' : 'rotate-0'}`}>
                                        <path d="M18 15l-6-6-6 6" />
                                    </svg>
                                </div>
                                {isOpen.card4 && (
                                    <div className="bg-white dark:bg-gray-800 overflow-hidden">
                                        <div className="mt-3 p-4 bg-gray-50 dark:bg-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors duration-200 rounded-lg shadow-lg">
                                            <div className="flex flex-wrap w-full p-4 bg-gray-50 dark:bg-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors duration-200 rounded-lg shadow-lg">
                                                <div className="px-2 w-full">
                                                    <label htmlFor="DeliveryBusinessName" className="text-sm font-medium text-gray-900 dark:text-gray-300 mb-1">
                                                        Contact Company:
                                                    </label>
                                                    <input
                                                        id="DeliveryBusinessName"
                                                        name="DeliveryBusinessName"
                                                        type="text"
                                                        value={formData.DeliveryBusinessName}
                                                        onChange={(e) => setFormData({ ...formData, DeliveryBusinessName: e.target.value })}
                                                        className="block p-2.5 w-full text-sm text-gray-900 bg-white border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                                        placeholder="Enter Contact Company"
                                                    />
                                                </div>

                                                <div className="px-2 w-full lg:w-1/3">
                                                    <label htmlFor="contactNamedelivery" className="text-sm font-medium text-gray-900 dark:text-gray-300 mb-1">
                                                        Contact Name:
                                                    </label>
                                                    <input
                                                        id="contactNamedelivery"
                                                        name="contactNamedelivery"
                                                        type="text"
                                                        value={formData.contactNamedelivery}
                                                        onChange={(e) => setFormData({ ...formData, contactNamedelivery: e.target.value })}
                                                        className="block p-2.5 w-full text-sm text-gray-900 bg-white border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                                        placeholder="Enter Contact Name"
                                                    />
                                                </div>

                                                <div className="px-2 w-full lg:w-1/3">
                                                    <label htmlFor="DeliveryDateEstimated" className="text-sm font-medium text-gray-900 dark:text-gray-300 mb-1">
                                                        Delivery Date Estimated:
                                                    </label>
                                                    <input
                                                        id="deliveryDateEstimated"
                                                        name="deliveryDateEstimated"
                                                        type="date"
                                                        value={formData.DeliveryDateEstimated}
                                                        onChange={(e) => setFormData({ ...formData, DeliveryDateEstimated: e.target.value })}
                                                        className="block p-2.5 w-full text-sm text-gray-900 bg-white border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                                    />
                                                </div>

                                                <div className="px-2 w-full lg:w-1/2">
                                                    <label htmlFor="emaildelivery" className="text-sm font-medium text-gray-900 dark:text-gray-300 mb-1">
                                                        Email:
                                                    </label>
                                                    <input
                                                        type="emaildelivery"
                                                        name="emaildelivery"
                                                        id="emaildelivery"
                                                        value={formData.emaildelivery || ''}
                                                        onChange={handleChange}
                                                        className="block p-2.5 w-full text-sm text-gray-900 bg-white border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                                        placeholder="Cajuu@email.com"
                                                    />
                                                </div>
                                                <div className="px-2 w-full lg:w-1/3">
                                                    <label htmlFor="phonedelivery" className="text-sm font-medium text-gray-900 dark:text-gray-300 mb-1">
                                                        Phone:
                                                    </label>
                                                    <input
                                                        type="tel"
                                                        name="phonedelivery"
                                                        id="phonedelivery"
                                                        value={formData.phonedelivery || ''}
                                                        onChange={handleChange}
                                                        className="block p-2.5 w-full text-sm text-gray-900 bg-white border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                                        placeholder="123-456-7890"
                                                    />
                                                </div>

                                                {/* <div className="px-2 w-full lg:w-1/3">
                                                    <label htmlFor="NoteDelivery" className="text-sm font-medium text-gray-900 dark:text-gray-300 mb-1">
                                                        Notes:
                                                    </label>
                                                    <textarea
                                                        id="NoteDelivery"
                                                        className="TextNote form-control w-full"
                                                        style={{ backgroundColor: 'azure', borderColor: 'gray', borderRadius: '1rem' }}

                                                        rows="5"
                                                        placeholder="   Enter your observations here..."
                                                    ></textarea>
                                                </div> */}

                                                {/* Expanded Notes section */}
                                                <div className="px-2 w-full">
                                                    <label htmlFor="NoteDelivery" className="text-sm font-medium text-gray-900 dark:text-gray-300 mb-1">
                                                        Notes:
                                                    </label>
                                                    <textarea
                                                        id="NoteDelivery"
                                                        className="TextNote form-control w-full"
                                                        style={{ backgroundColor: 'azure', borderColor: 'gray', borderRadius: '1rem' }}
                                                        rows="5"
                                                        placeholder="   Enter your observations here..."
                                                    ></textarea>
                                                </div>


                                                <div className="flex flex-wrap -mx-2">
                                                    {/* Address, City, and State fields sharing the width equally on larger screens */}
                                                    <div className="px-2 w-full">
                                                        <label htmlFor="addressdelivery" className="text-sm font-medium text-gray-900 dark:text-gray-300 mb-1">
                                                            Address:
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="addressdelivery"
                                                            id="addressdelivery"
                                                            required
                                                            value={formData.addressdelivery || ''}
                                                            onChange={handleChange}
                                                            className="block p-2.5 w-full text-sm text-gray-900 bg-white border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                                            placeholder="Enter the Address"
                                                        />
                                                    </div>

                                                    <div className="px-2 w-full lg:w-1/3">
                                                        <label htmlFor="citydelivery" className="text-sm font-medium text-gray-900 dark:text-gray-300 mb-1">
                                                            City:
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="citydelivery"
                                                            id="citydelivery"
                                                            required
                                                            value={formData.citydelivery || ''}
                                                            onChange={handleChange}
                                                            className="block p-2.5 w-full text-sm text-gray-900 bg-white border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                                            placeholder="Enter the City"
                                                        />
                                                    </div>

                                                    <div className="px-2 w-full lg:w-1/3">
                                                        <label htmlFor="Statedelivery" className="text-sm font-medium text-gray-900 dark:text-gray-300 mb-1">
                                                            State:
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="Statedelivery"
                                                            id="Statedelivery"
                                                            required
                                                            value={formData.Statedelivery || ''}
                                                            onChange={handleChange}
                                                            className="block p-2.5 w-full text-sm text-gray-900 bg-white border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                                            placeholder="Enter the State"
                                                        />
                                                    </div>

                                                    {/* Zipcode field placed on a separate line */}
                                                    <div className="px-2 w-full lg:w-1/3">
                                                        <label htmlFor="zipcodedelivery" className="text-sm font-medium text-gray-900 dark:text-gray-300 mb-1">
                                                            Zipcode:
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="zipcodedelivery"
                                                            id="zipcodedelivery"
                                                            required
                                                            value={formData.zipcodedelivery || ''}
                                                            onChange={handleChange}
                                                            className="block p-2.5 w-full text-sm text-gray-900 bg-white border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                                            placeholder="Enter the Zipcode"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Card>
                        </div>
                    </div>

                    <div className="flex justify-center mt-8 mb-4">
                        <Button
                            type="submit"
                            className="flex items-center justify-center mx-2 mr-2 py-2 px-4 rounded text-white font-bold"
                            style={{ background: 'orange' }}
                        >
                            <RiSendPlaneFill className="mr-2" />Submit
                        </Button>
                        <Button
                            type="button"
                            className="flex items-center justify-center mx-2 mr-2 py-2 px-4 rounded text-white font-bold"
                            style={{ background: 'red' }}
                            onClick={() => setFormData(initialFormData)}
                        >
                            <GrPowerReset className="mr-2" />Reset
                        </Button>
                    </div>
                </form >
            )}
        </div >
    );
};

export default FormSubmitHandler;