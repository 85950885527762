import React, { useState, useEffect, useRef } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import MainSpinner from '../components/MainSpinner';
import SignaturePad from 'signature_pad';

const StaffInvitation = () => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    username: '',
    profile_picture_path: '',
    password: '',
    confirmPassword: '',
    user_role_id: '1', // Admin por padrão
  });
  const [loading, setLoading] = useState(true);
  const [isDriver, setIsDriver] = useState(false);
  const signaturePadRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (isDriver && canvasRef.current) {
      const canvas = canvasRef.current;
      signaturePadRef.current = new SignaturePad(canvas);
    }
  }, [isDriver]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Atualiza o estado para exibir os campos do motorista
    if (name === 'user_role_id' && value === '2') {
      setIsDriver(true);
    } else {
      setIsDriver(false);
    }
  };

  const handleInvite = async (event) => {
    event.preventDefault();

    try {
      const partnerCompanyId = localStorage.getItem('companyId') || 1;

      // Capturar a assinatura se for um motorista
      let signatureBlob = null;
      if (isDriver && signaturePadRef.current && !signaturePadRef.current.isEmpty()) {
        const dataURL = signaturePadRef.current.toDataURL('image/png');
        const byteString = atob(dataURL.split(',')[1]);
        const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
          uint8Array[i] = byteString.charCodeAt(i);
        }
        signatureBlob = new Blob([uint8Array], { type: mimeString });
      }

      // Criar um objeto FormData para enviar os dados
      const formDataToSend = new FormData();
      formDataToSend.append('email', formData.email);
      formDataToSend.append('user_role_id', formData.user_role_id);
      formDataToSend.append('first_name', formData.first_name);
      formDataToSend.append('last_name', formData.last_name);
      formDataToSend.append('phone', formData.phone);
      formDataToSend.append('username', formData.username);
      formDataToSend.append('password', formData.password);
      formDataToSend.append('c_password', formData.confirmPassword);

      // Se for motorista, adicionar campos específicos
      if (isDriver) {
        formDataToSend.append('partner_company_id', partnerCompanyId);
        formDataToSend.append('trailer_type', formData.trailer_type);
        if (signatureBlob) {
          formDataToSend.append('signature', signatureBlob, 'signature.png');
        }
      }

      const endpoint = isDriver
        ? `${process.env.REACT_APP_API_PREFIX}/user-driver`
        : `${process.env.REACT_APP_API_PREFIX}/User`;

      console.log('Form Data:', {
        email: formData.email,
        user_role_id: formData.user_role_id,
        first_name: formData.first_name,
        last_name: formData.last_name,
        phone: formData.phone,
        username: formData.username,
        password: formData.password,
        c_password: formData.confirmPassword,
        ...(isDriver && {
          partner_company_id: partnerCompanyId,
          trailer_type: formData.trailer_type,
          signature: signatureBlob ? 'Signature file attached' : 'No signature',
        }),
      });

      // Enviar os dados para o endpoint correto
      const response = await axios.post(endpoint, formDataToSend);

      console.log("Response from server:", response.data);

      // Exibir alerta de sucesso com temporizador de 5 segundos e redirecionar para o dashboard
      Swal.fire({
        title: 'Success!',
        text: 'Registration successful! You will be redirected shortly.',
        icon: 'success',
        timer: 5000, // Temporizador de 5 segundos
        timerProgressBar: true,
        showConfirmButton: false // Remove o botão de confirmação
      }).then(() => {
        window.location.href = '/dashboard'; // Redirecionar para o dashboard
      });

    } catch (err) {
      if (err.response && err.response.data) {
        Swal.fire({
          title: 'Error!',
          text: `Registration not completed! ${err.response.data.message}`,
          icon: 'error',
          confirmButtonText: 'OK'
        });
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Registration not completed! An unknown error occurred.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    }
  };

  if (loading) {
    return <MainSpinner />;
  }

  return (
    <div className="min-h-screen flex justify-center items-center bg-gray-50">
      <div className="bg-white shadow-lg rounded-lg w-full max-w-4xl p-8 mx-4 md:mx-0" style={{marginTop:'-11rem'}}>
        <h2 className="text-2xl font-bold text-gray-700 text-center mb-6">Employee Registration</h2>
        <form onSubmit={handleInvite} className="space-y-6">
          {/* Seleção de papel (Role) */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label htmlFor="user_role_id" className="block text-sm font-medium text-gray-600">Role</label>
              <select
                id="user_role_id"
                name="user_role_id"
                value={formData.user_role_id}
                onChange={handleChange}
                className="w-full border border-gray-300 p-3 rounded-md focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="1">Admin</option>
                <option value="7">Dispatcher</option>
                <option value="9">Accountant</option>
                <option value="2">Driver</option>
              </select>
            </div>
          </div>

          {/* Campos gerais */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="flex space-x-4">
              <div className="flex-1">
                <label htmlFor="first_name" className="block text-sm font-medium text-gray-600">First Name</label>
                <input
                  id="first_name"
                  name="first_name"
                  type="text"
                  value={formData.first_name}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-3 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter first name"
                />
              </div>
              <div className="flex-1">
                <label htmlFor="last_name" className="block text-sm font-medium text-gray-600">Last Name</label>
                <input
                  id="last_name"
                  name="last_name"
                  type="text"
                  value={formData.last_name}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-3 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter last name"
                />
              </div>
            </div>

            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-600">Email</label>
              <input
                id="email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full border border-gray-300 p-3 rounded-md focus:ring-blue-500 focus:border-blue-500"
                placeholder="email@example.com"
              />
            </div>
            <div className="flex space-x-4">
              <div className="flex-1">
                <label htmlFor="phone" className="block text-sm font-medium text-gray-600">Phone</label>
                <input
                  id="phone"
                  name="phone"
                  type="tel"
                  value={formData.phone}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-3 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  placeholder="(123) 456-7890"
                />
              </div>

              <div className="flex-1">
                <label htmlFor="username" className="block text-sm font-medium text-gray-600">Username</label>
                <input
                  id="username"
                  name="username"
                  type="text"
                  value={formData.username}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-3 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter username"
                />
              </div>
            </div>

            <div className="flex space-x-4">
              <div className="flex-1">
                <label htmlFor="password" className="block text-sm font-medium text-gray-600">Password</label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={formData.password}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-3 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter password"
                />
              </div>

              <div className="flex-1">
                <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-600">Confirm Password</label>
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-3 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Confirm password"
                />
              </div>
            </div>
          </div>

          {/* Campos para motorista (visíveis apenas se o papel "Driver" for selecionado) */}
          {isDriver && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label htmlFor="trailer_type" className="block text-sm font-medium text-gray-600">Trailer Type</label>
                <select
                  id="trailer_type"
                  name="trailer_type"
                  value={formData.trailer_type}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-3 rounded-md focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">Select Trailer Type</option>
                  <option value="open">Open</option>
                  <option value="enclosed">Enclosed</option>
                </select>
              </div>

              <div className="col-span-2 mt-4">
                <label className="block text-sm font-medium text-gray-600">Signature</label>
                <canvas
                  ref={canvasRef}
                  width={400}
                  height={150}
                  className="border border-gray-300 rounded-md"
                ></canvas>
                <button
                  type="button"
                  onClick={() => signaturePadRef.current.clear()}
                  className="mt-2 px-4 py-2 bg-red-500 text-white rounded-md"
                >
                  Clear Signature
                </button>
              </div>
            </div>
          )}

          <button
            type="submit"
            style={{
              backgroundColor: '#FF8C00',
              color: 'white',
              padding: '12px',
              borderRadius: '6px',
              fontWeight: 'bold',
              width: '100%',
              marginTop: '16px',
              transition: 'background-color 0.3s ease',
            }}
            onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#FFA500'}
            onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#FF8C00'}
          >
            Register
          </button>
        </form>
      </div>
    </div>
  );
};

export default StaffInvitation;
