import React, { useRef, forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import SignaturePad from 'signature_pad';
import { AiOutlineClear } from "react-icons/ai";

const SignatureSection = forwardRef((props, ref) => {
  const canvasRef = useRef(null);
  const [signaturePad, setSignaturePad] = useState(null);
  const [savedSignature, setSavedSignature] = useState(null);

  // Expondo os métodos clear() e save() através da referência
  useImperativeHandle(ref, () => ({
    clear() {
      if (signaturePad) {
        signaturePad.clear();
        setSavedSignature(null); // Limpa a assinatura salva
        localStorage.removeItem('savedSignature'); // Remove do localStorage
      }
    },
    save() {
      if (signaturePad && !signaturePad.isEmpty()) {
        const signature = signaturePad.toDataURL('image/png');
        setSavedSignature(signature); // Salva a assinatura no estado
        localStorage.setItem('savedSignature', signature); // Salva no localStorage
        return signature;
      }
      return null;
    },
  }), [signaturePad]);

  // Função para restaurar a assinatura salva
  const restoreSignature = () => {
    const savedSignatureData = localStorage.getItem('savedSignature');
    if (savedSignatureData && signaturePad) {
      const img = new Image();
      img.src = savedSignatureData;
      img.onload = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height); // Limpa o canvas antes de desenhar
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      };
    }
  };

  // Ajusta o tamanho do canvas para corresponder ao dispositivo e prevenir problemas de deslocamento
  const resizeCanvas = () => {
    const canvas = canvasRef.current;
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext('2d').scale(ratio, ratio);
    // Limpar o canvas para garantir fundo transparente
    canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
  };

  // Inicializando o SignaturePad e restaurando a assinatura quando o componente for montado
  useEffect(() => {
    const canvas = canvasRef.current;
    resizeCanvas();
    const sigPad = new SignaturePad(canvas, {
      backgroundColor: 'rgba(0, 0, 0, 0)', // Deixa o fundo transparente
      penColor: 'black',
      minWidth: 1,  // Largura mínima do traço da caneta
      maxWidth: 3,  // Largura máxima do traço da caneta
    });
    setSignaturePad(sigPad);
    restoreSignature();
  }, []);

  // Atualiza o tamanho do canvas quando a janela é redimensionada
  useEffect(() => {
    window.addEventListener('resize', resizeCanvas);
    return () => {
      window.removeEventListener('resize', resizeCanvas);
    };
  }, []);

  return (
    <div className="flex flex-col m-2 w-full mt-40 text-center -ml-21important">
      <div className="mb-2 block">
        <label htmlFor="customerSignature" className="block text-sm font-medium leading-6 text-gray-900 text-center">
          Customer Signature
        </label>
      </div>
      <div>
        <canvas
          ref={canvasRef}
          className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg"
          style={{ width: '90%', maxWidth: '500px', height: '200px', maxHeight: '200px' }}
        ></canvas>
      </div>
      <button onClick={() => signaturePad.clear()} className="mt-2 px-4 py-2 bg-orange-500 text-white rounded-md w-12">
        <AiOutlineClear />
      </button>
    </div>
  );
});

export default SignatureSection;