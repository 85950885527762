import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { Button, Card } from "@material-tailwind/react";
import { FaInfoCircle, FaCar } from "react-icons/fa";
import { MdOutlinePayments } from "react-icons/md";
import { TbMapShare } from "react-icons/tb";
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import Spinner from '../components/MainSpinner'; // Ensure the spinner component is correctly imported
import { RiSendPlaneFill } from "react-icons/ri";
import { GrPowerReset } from "react-icons/gr";
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { useDropzone } from 'react-dropzone';
import { useHistory } from 'react-router-dom';
import centraldispatchLogo from '../assets/img/centraldispatch.png';
import ProgressBar from '../components/ProgressBar';
import { IoMdAddCircle } from "react-icons/io";
import { FaFilePdf } from "react-icons/fa6";

const API_PATH_PREFIX = 'https://new.cajuu.app/api';


const initialFormData = {
    orderId: '', // LoadId
    paymentRate: '',
    paymentType: '',
    citypickup: '',
    Statepickup: '',
    zipcodepickup: '',
    citydelivery: '',
    Statedelivery: '',
    zipcodedelivery: '',
    cars: [
        { year: '', make: '', model: '', carType: '', isOperable: '', vin: '', lotNumber: '', buyerNumber: '' }
    ],
    addresspickup: '',
    addressdelivery: '',
    PickupBusinessName: '',
    contactNamepickup: '',
    phonepickup: '',
    emailpickup: '',
    DeliveryBusinessName: '',
    contactNamedelivery: '',
    phonedelivery: '',
    emaildelivery: '',
};



const FormSubmitHandler = () => {
    const [formData, setFormData] = useState(initialFormData);
    const [driver, setDriver] = useState([]);
    const [partnerCompanies, setPartnerCompanies] = useState([]);
    const [shippers, setShippers] = useState([]);
    const [filteredShippers, setFilteredShippers] = useState([]);
    const [carTypes, setCarTypes] = useState([]);
    const { vehicleId } = useParams();
    const [isInspectionCompleted, setIsInspectionCompleted] = useState(false);
    const [pdfFile, setPdfFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadStatus, setUploadStatus] = useState(null);
    const [isDragActive, setIsDragActive] = useState(false);
    const [shipperSearch, setShipperSearch] = useState('');
    const cancelTokenSource = useRef(null);
    const history = useHistory();
    const suggestions = [];
    const [shipperMessage, setShipperMessage] = useState('');  // Estado para armazenar a mensagem de aviso
    const [shipperExists, setShipperExists] = useState(false);  // Estado para indicar se o shipper já existe
    const [showRemoveCarButton, setShowRemoveCarButton] = useState(false);
    const [highlightedFields, setHighlightedFields] = useState([]); // State to track fields with errors



    const [loading, setLoading] = useState(false); // Loading state
    const [isOpen, setIsOpen] = useState({
        card1: false,
        card2: false,
        card3: false,
        card0: false,
        card4: false,
    });
    const [showAddShipperForm, setShowAddShipperForm] = useState(false);
    const [newShipperData, setNewShipperData] = useState({

    });

    const onDrop = (acceptedFiles) => {
        if (acceptedFiles && acceptedFiles[0]) {
            setPdfFile(acceptedFiles[0]);
            setUploadProgress(0); // Reset progress before new upload
            setUploadStatus(null); // Reset status
            uploadFile(acceptedFiles[0]);
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'application/pdf',
        maxSize: 4 * 1024 * 1024, // 4 MB
        onDragEnter: () => setIsDragActive(true),
        onDragLeave: () => setIsDragActive(false),
    });

    const formatDateString = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };


    let shipperCreated = false; // Variável para garantir que `createShipper` seja chamada apenas uma vez

    const uploadFile = async (file) => {
        setUploadProgress(0); // Inicia o progresso a partir de zero
        try {
            const userToken = localStorage.getItem('userToken');
            const formData = new FormData();
            formData.append('pdf', file);

            // Passo 1: Faz o upload do PDF e extrai os dados
            const response = await axios.post(`${API_PATH_PREFIX}/import_pdf`, formData, {
                headers: {
                    "Authorization": `Bearer ${userToken}`,
                },
                onUploadProgress: (progressEvent) => {
                    const percentComplete = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(percentComplete);
                }
            });

            const extractedData = response.data;
            console.log('API response data:', extractedData);

            if (!extractedData) {
                throw new Error('Extracted data is not available in the API response.');
            }

            // Passo 2: Preenche os campos do formulário com os dados extraídos
            setFormData({
                orderId: extractedData.load_id ?? '',
                paymentRate: extractedData.total_price ?? '',
                addresspickup: extractedData.origin?.address ?? '',
                citypickup: extractedData.origin?.city ?? '',
                Statepickup: extractedData.origin?.state ?? '',
                zipcodepickup: extractedData.origin?.zip_code ?? '',
                pickupDateEstimated: formatDateString(extractedData.scheduled_pickup_estimated) ?? '',
                contactNamepickup: extractedData.origin?.company ?? '',
                phonepickup: extractedData.carrier?.phone ?? '',
                addressdelivery: extractedData.destination?.address ?? '',
                citydelivery: extractedData.destination?.city ?? '',
                Statedelivery: extractedData.destination?.state ?? '',
                zipcodedelivery: extractedData.destination?.zip_code ?? '',
                contactNamedelivery: extractedData.destination?.location ?? '',
                cars: [{
                    vin: extractedData.vin ?? '',
                    make: extractedData.vehicle_make ?? '',
                    model: extractedData.vehicle_model ?? '',
                    year: extractedData.vehicle_year ?? '',
                    isOperable: '',
                }]
            });

            // Adiciona um pequeno atraso para garantir que os dados foram preenchidos corretamente
            await new Promise(resolve => setTimeout(resolve, 11000));

            // Passo 3: Agora que os dados foram extraídos e preenchidos, vamos verificar se o shipper já existe
            if (extractedData.shipper && !shipperCreated) { // Verifica se já foi criado
                const shipperData = extractedData.shipper;

                // Garante que o `createShipper` só seja chamado uma vez
                const exists = await checkShipperExists(shipperData.name);

                // Verificação adicional para ver se o `shipper` já existe
                if (!exists && !shipperCreated) {
                    shipperCreated = true; // Marca que o shipper está sendo criado
                    console.log('Creating shipper:', shipperData.name, shipperData.address_line_1, shipperData.address_line_2);

                    const newShipperId = await createShipper({
                        shipperName: shipperData.name,
                        address_line_1: shipperData.address_line_1 || '',
                        address_line_2: shipperData.address_line_2 || '',
                    });

                    if (newShipperId) {
                        // Define o ID do shipper criado no formulário
                        setFormData(prevFormData => ({
                            ...prevFormData,
                            shipper: newShipperId, // ID do novo shipper
                        }));
                        setShipperSearch(shipperData.name); // Atualiza o campo de busca com o nome do shipper
                    }
                } else {
                    // Se o shipper já existir, atualiza o formulário com o ID do shipper existente
                    setFormData(prevFormData => ({
                        ...prevFormData,
                        shipper: exists, // Detalhes ou ID do shipper existente
                    }));
                    setShipperSearch(shipperData.name); // Atualiza o campo de busca com o nome do shipper
                }
            }

            setUploadStatus('success'); // Define o status do upload como sucesso
            setUploadProgress(0); // Reseta o progresso após o sucesso
            Swal.fire({
                title: 'Success!',
                text: 'File uploaded successfully!',
                icon: 'success',
                confirmButtonText: 'Great!',
            });

        } catch (error) {
            setUploadStatus('error'); // Define o status do upload como erro
            setUploadProgress(0); // Reseta o progresso após erro
            console.error('Error uploading file:', error.message);
            Swal.fire({
                title: 'Error!',
                text: 'Failed to upload file. Please try again.',
                icon: 'error',
                confirmButtonText: 'Retry',
            });
        }
    };

    const createShipper = async (shipperData) => {
        let loading = null;
        try {
            loading = Swal.fire({
                title: 'Loading...',
                text: 'Please wait.',
                allowOutsideClick: false,
                didOpen: () => Swal.showLoading(),
            });

            const userToken = localStorage.getItem('userToken');

            const requestData = {
                shipper_name: shipperData.shipperName,
                address_line_1: shipperData.address_line_1 || '',
                address_line_2: shipperData.address_line_2 || '',
            };

            console.log('Data being sent:', requestData);

            // Enviar a requisição para criar o shipper
            const response = await axios.post(`${API_PATH_PREFIX}/shipper`, requestData, {
                headers: {
                    "Screen-Name": "/NewLoad",
                    "Authorization": `Bearer ${userToken}`
                }
            });

            if (response.status === 200 || response.status === 201) {
                Swal.fire({
                    title: 'Success!',
                    text: 'Shipper added successfully.',
                    icon: 'success',
                    timer: 2000,
                });
                return response.data.data.id; // Retorna o ID do novo shipper
            } else {
                throw new Error('Failed to add shipper.');
            }

        } catch (error) {
            console.error('Error during shipper creation:', error.message); // Adicionado para verificar erros no processo
            Swal.fire({
                title: 'Error!',
                text: 'Could not add the shipper. Please try again.',
                icon: 'error',
            });
            return null;
        } finally {
            if (loading) Swal.close();
        }
    };

    const checkShipperExists = async (shipperName) => {
        try {
            const formattedShipperName = shipperName.trim().toLowerCase();

            const foundShipper = shippers.find(shipper => shipper.shipper_name.toLowerCase() === formattedShipperName);

            if (foundShipper) {
                // If the Shipper exists
                setFormData(prevFormData => ({
                    ...prevFormData,
                    shipper: foundShipper.id,
                }));
                setShipperSearch(foundShipper.shipper_name);
                setFilteredShippers([]);
                setShipperExists(true);  // Indicate that the shipper exists
                setShipperMessage(`The Shipper "${foundShipper.shipper_name}" already exists in the system.`);
                return true;
            } else {
                // If the Shipper does not exist, return false
                return false;
            }
        } catch (error) {
            console.error("Error verifying or creating the Shipper:", error);
            setShipperMessage("Error verifying or creating the shipper.");
            return false;
        }
    };





    const toggleCard = (cardKey) => {
        setIsOpen(prev => ({
            ...prev,
            [cardKey]: !prev[cardKey]
        }));
    };

    const fetchCarTypes = useCallback(async () => {
        try {
            setLoading(true); // Start loading
            const userToken = localStorage.getItem('userToken');

            const response = await axios.get(`${API_PATH_PREFIX}/cartype`, {
                headers: {
                    "Screen-Name": "/NewLoad",
                    "Authorization": `Bearer ${userToken}`
                }
            });
            setCarTypes(response.data.data);
        } catch (error) {
            console.error("Erro ao buscar carTypes", error);
        } finally {
            setLoading(false); // End loading
        }
    }, []);

    useEffect(() => {
        fetchCarTypes();
    }, [fetchCarTypes]);

    const fetchDriver = useCallback(async () => {
        try {
            setLoading(true); // Start loading
            const userToken = localStorage.getItem('userToken');

            const response = await axios.get(`${API_PATH_PREFIX}/driver`, {
                headers: {
                    "Screen-Name": "/NewLoad",
                    "Authorization": `Bearer ${userToken}`
                }
            });
            setDriver(response.data.data);
        } catch (error) {
            console.error("Erro ao buscar drivers", error);
        } finally {
            setLoading(false); // End loading
        }
    }, []);

    const fetchPartnerCompanies = useCallback(async () => {
        try {
            setLoading(true); // Start loading
            const userToken = localStorage.getItem('userToken');

            const response = await axios.get(`${API_PATH_PREFIX}/partnercompany`, {
                headers: {
                    "Screen-Name": "/NewLoad",
                    "Authorization": `Bearer ${userToken}`
                }
            });

            setPartnerCompanies(response.data.data);
        } catch (error) {
            console.error("Erro ao buscar partner companies", error);
        } finally {
            setLoading(false); // End loading
        }
    }, []);

    const fetchShippers = useCallback(async () => {
        try {
            setLoading(true); // Start loading
            const userToken = localStorage.getItem('userToken');

            const response = await axios.get(`${API_PATH_PREFIX}/shipper`, {
                headers: {
                    "Screen-Name": "/NewLoad",
                    "Authorization": `Bearer ${userToken}`
                }
            });
            setShippers(response.data.data);
        } catch (error) {
            console.error("Erro ao buscar shippers", error);
        } finally {
            setLoading(false); // End loading
        }
    }, []);

    useEffect(() => {
        fetchDriver();
        fetchPartnerCompanies();
        fetchShippers();
    }, [fetchDriver, fetchPartnerCompanies, fetchShippers]);

    useEffect(() => {
        setFilteredShippers(shippers);
    }, [shippers]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        if (name === 'shipperSearch') {
            setShipperSearch(value);
            filterShippers(value);
        }
    };

    const filterShippers = (searchTerm) => {
        if (searchTerm === '') {
            setFilteredShippers(shippers);
        } else {
            const filtered = shippers.filter(shipper =>
                shipper.shipper_name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredShippers(filtered);
        }
    };

    useEffect(() => {
        if (formData.cars.length === 9) {
            Swal.fire({
                title: 'Maximum Limit Reached',
                text: 'You have already added the maximum number of 9 cars.',
                icon: 'warning',
                confirmButtonText: 'Got it',
                timer: 2000, // Closes after 2 seconds
                timerProgressBar: true,
                customClass: {
                    popup: 'swal-popup-custom',
                    title: 'swal-title-custom',
                    htmlContainer: 'swal-text-custom',
                    confirmButton: 'swal-confirm-btn'
                },
                didOpen: () => {
                    // Apply inline styling directly
                    const swalPopup = document.querySelector('.swal-popup-custom');
                    swalPopup.style.backgroundColor = '#ffffff'; // White background
                    swalPopup.style.border = '1px solid #ff9800'; // Orange border for a clean look

                    const swalTitle = document.querySelector('.swal-title-custom');
                    swalTitle.style.color = '#ff9800'; // Orange title text
                    swalTitle.style.fontWeight = 'bold'; // Bold for title to stand out

                    const swalText = document.querySelector('.swal-text-custom');
                    swalText.style.color = '#333333'; // Neutral gray for the text to maintain readability

                    const swalConfirmButton = document.querySelector('.swal-confirm-btn');
                    swalConfirmButton.style.backgroundColor = '#ffffff'; // White button background
                    swalConfirmButton.style.color = '#ff9800'; // Orange button text
                    swalConfirmButton.style.border = '1px solid #ff9800'; // Orange border
                    swalConfirmButton.style.padding = '8px 16px';
                    swalConfirmButton.style.borderRadius = '4px';

                    // Hover effect
                    swalConfirmButton.addEventListener('mouseover', () => {
                        swalConfirmButton.style.backgroundColor = '#ff9800';
                        swalConfirmButton.style.color = '#ffffff';
                    });
                    swalConfirmButton.addEventListener('mouseout', () => {
                        swalConfirmButton.style.backgroundColor = '#ffffff';
                        swalConfirmButton.style.color = '#ff9800';
                    });
                }
            });


        }
    }, [formData.cars]);


    const addCar = () => {
        if (formData.cars.length < 9) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                cars: [...prevFormData.cars, { year: '', make: '', model: '', carType: '', isOperable: '' }]
            }));
            setShowRemoveCarButton(true); // Exibe o botão de remoção
        }
    };





    // const deleteLastCar = () => {
    //     if (formData.cars.length > 1) {
    //         const updatedCars = formData.cars.slice(0, -1);
    //         setFormData({ ...formData, cars: updatedCars });
    //         if (updatedCars.length === 1) {
    //             setShowRemoveCarButton(false); // Hide remove button when only one car remains
    //         }
    //     }
    // };

    const deleteLastCar = () => {
        if (formData.cars.length > 1) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                cars: prevFormData.cars.slice(0, -1)
            }));
        }
        if (formData.cars.length <= 2) { // Oculta o botão se restar apenas um carro
            setShowRemoveCarButton(false);
        }
    };




    // const deleteCar = (index) => {
    //     const updatedCars = formData.cars.filter((_, i) => i !== index);
    //     setFormData({ ...formData, cars: updatedCars });
    // };



    const handleCarChange = (carIndex, e) => {
        const updatedCars = formData.cars.map((car, index) => {
            if (index === carIndex) {
                return { ...car, [e.target.name]: e.target.value };
            }
            return car;
        });
        setFormData({ ...formData, cars: updatedCars });
    };

    const removeLastCar = () => {
        setFormData({ ...formData, cars: formData.cars.slice(0, -8) });
    };

    // const validateFormData = (data) => {
    //     const requiredFields = {
    // orderId: 'Order ID',
    // shipperSearch: "shipper",
    // paymentRate: 'Payment Rate',
    // paymentType: 'Payment Type',
    // citypickup: 'Pickup City',
    // Statepickup: 'Pickup State',
    // zipcodepickup: 'Pickup Zipcode',
    // citydelivery: 'Delivery City',
    // Statedelivery: 'Delivery State',
    // zipcodedelivery: 'Delivery Zipcode',
    // pickup_address: 'Pickup Address',
    // delivery_address: 'Delivery Address',
    // pickup_business_name / Obrigatorio no BD
    // delivery_business_name / Obrigatorio no BD
    // pickup_customer_phone / Obrigatorio no BD
    // delivery_customer_phone / Obrigatorio no BD
    // pickup_customer_phone / Obrigatorio no BD
    // delivery_customer_phone / Obrigatorio no BD
    // loadboard / Obrigatorio no BD
    // expected_pickup_date / Obrigatorio no BD
    // expected_delivery_date / Obrigatorio no BD
    // buyer_number / Obrigatorio no BD
    // lot_number / Obrigatorio no BD
    //     };

    //     const errors = {};

    //     Object.keys(requiredFields).forEach(field => {
    //         if (!data[field]) {
    //             errors[field] = `The ${requiredFields[field]} field is required.`;
    //         }
    //     });

    //     data.cars.forEach((car, index) => {
    //         if (!car.make || !car.model || !car.year || !car.carType) {
    //             errors[`car${index}`] = `All vehicle details (make, model, year, car type) must be filled for car ${index + 1}.`;
    //         }
    //     });

    //     return errors;
    // };

    const handleVinChange = (index, event) => {
        const { name, value } = event.target;
        if (value.length === 17) {
            fetchVehicleData(index, value);
        } else if (value.length > 17) {
            Swal.fire({
                title: 'Error!',
                text: 'VIN must be 17 characters long.',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        }
        handleCarChange(index, event);
    };

    const handleAddShipper = async (e) => {
        e.preventDefault();

        let loading;
        try {
            // Inicia o spinner de carregamento
            loading = Swal.fire({
                title: 'Loading...',
                text: 'Please wait.',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            });

            const userToken = localStorage.getItem('userToken');

            // Tempo mínimo que o loading deve ficar visível (em milissegundos)
            const minimumLoadingTime = 2000; // 2 segundos
            const loadingStartTime = Date.now();

            const response = await axios.post(`${API_PATH_PREFIX}/shipper`, {
                shipper_name: newShipperData.shipperName,
                accounting_email: newShipperData.accountingEmail,
                phone: newShipperData.shipperPhone,
                type: newShipperData.shipperType,
                info_email: newShipperData.infoEmail,
                address: newShipperData.address,
                city: newShipperData.city,
                state: newShipperData.state,
                zip_code: newShipperData.zipcode
            }, {
                headers: {
                    "Screen-Name": "/NewLoad",
                    "Authorization": `Bearer ${userToken}`
                }
            });

            // Calcula o tempo restante para garantir o tempo mínimo de loading
            const elapsedLoadingTime = Date.now() - loadingStartTime;
            const remainingLoadingTime = minimumLoadingTime - elapsedLoadingTime;

            if (remainingLoadingTime > 0) {
                // Se o tempo de loading foi menor que o mínimo, aguarda o tempo restante
                await new Promise(resolve => setTimeout(resolve, remainingLoadingTime));
            }

            // Fechar o spinner antes de continuar
            Swal.close();

            if (response.status === 200 || response.status === 201) {
                // Atualizar a lista de shippers com o novo shipper
                setShippers(prev => [...prev, response.data.data]);
                setShowAddShipperForm(false); // Fechar o formulário
                setShipperSearch(''); // Limpar o campo de busca
                setNewShipperData({
                    shipperName: '',
                    accountingEmail: '',
                    shipperPhone: '',
                    shipperType: '',
                    infoEmail: '',
                    address: '',
                    city: '',
                    state: '',
                    zipcode: ''
                }); // Limpar os campos do formulário

                // Exibir mensagem de sucesso
                Swal.fire({
                    title: 'Success!',
                    text: 'Shipper added successfully.',
                    icon: 'success',
                    timer: 5000, // Aumenta o tempo para 5 segundos
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                });
            } else {
                // Se o status da API não for 200 ou 201, trata como erro
                Swal.fire({
                    title: 'Error!',
                    text: 'Could not add the shipper. Please try again.',
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                });
            }
        } catch (error) {
            // Certifique-se de que o spinner foi fechado antes de mostrar o erro
            if (loading) Swal.close();

            Swal.fire({
                title: 'Error!',
                text: 'Could not add the shipper. Please try again.',
                icon: 'error',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
            });
        } finally {
            // Garantir que o spinner seja fechado, mesmo em caso de falha
            if (loading) Swal.close();
        }


    };

    const validateRequiredFields = () => {
        const requiredFields = [
            'orderId', 'paymentRate', 'citypickup', 'Statepickup', 'zipcodepickup',
            'citydelivery', 'Statedelivery', 'zipcodedelivery', 'addresspickup', 'addressdelivery'
        ];

        const emptyFields = requiredFields.filter((field) => {
            return formData[field] === undefined || formData[field] === '';
        });

        formData.cars.forEach((car, index) => {
            ['year', 'make', 'model', 'carType', 'isOperable'].forEach((carField) => {
                if (!car[carField]) emptyFields.push(`car-${index}-${carField}`);
            });
        });

        setHighlightedFields(emptyFields);
        return emptyFields.length === 0;
    };




    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Form Data:', formData); // Adicione este log
        if (!validateRequiredFields()) { // Chama a função de validação
            Swal.fire({
                title: 'Missing required fields',
                text: 'Please complete all required fields before submitting.',
                icon: 'warning',
                // confirmButtonText: 'Entendi!',
                timer: 2000,
            });
            return; // Interrompe o envio caso campos obrigatórios não estejam preenchidos
        }
        setLoading(true);

        try {
            const userToken = localStorage.getItem('userToken');

            let shipperId = formData.shipper;
            if (!shipperId) {
                const shipperResponse = await axios.post(`${API_PATH_PREFIX}/shipper`, newShipperData, {
                    headers: {
                        "Screen-Name": "/NewLoad",
                        "Authorization": `Bearer ${userToken}`
                    }
                });

                if (shipperResponse.status === 200) {
                    shipperId = shipperResponse.data.id;
                    setShipperSearch(newShipperData.shipperName);
                }
            }

            const payload = {
                driver_id: formData.driver === "" ? null : parseInt(formData.driver),
                shipper_id: shipperId,
                loadboard: formData.loadboard,
                order_id: formData.orderId,
                pickup_address: formData.addresspickup,
                pickup_city: formData.citypickup,
                pickup_state: formData.Statepickup,
                pickup_zipcode: formData.zipcodepickup,
                pickup_customer_name: formData.contactNamepickup,
                pickup_customer_phone: formData.phonepickup,
                pickup_customer_email: formData.emailpickup,
                pickup_business_name: formData.PickupBusinessName,
                delivery_address: formData.addressdelivery,
                delivery_city: formData.citydelivery,
                delivery_state: formData.Statedelivery,
                delivery_zipcode: formData.zipcodedelivery,
                delivery_customer_name: formData.contactNamedelivery,
                delivery_customer_phone: formData.phonedelivery,
                delivery_customer_email: formData.emaildelivery,
                delivery_business_name: formData.DeliveryBusinessName,
                payment_rate: parseFloat(formData.paymentRate),
                payment_method: formData.paymentMethod,
                payment_type: formData.paymentType,
                dispatch_date: new Date().toISOString().split('T')[0],
                expected_pickup_date: formData.pickupDateEstimated,
                expected_dropoff_date: formData.deliveryDateEstimated,
                load_items: formData.cars.map(car => ({
                    car_type_id: car.carType ? parseInt(car.carType) : null,
                    model: car.model,
                    year: car.year,
                    vin: car.vin,
                    make: car.make,
                    buyer_number: car.buyerNumber,
                    lot_number: car.lotNumber,
                    is_operable: car.isOperable === 'true',
                    additional_info: car.additionalInfo || ""
                }))
            };

            const response = await axios.post(`${API_PATH_PREFIX}/loadtransport`, payload, {
                headers: {
                    "Screen-Name": "/NewLoad",
                    "Authorization": `Bearer ${userToken}`
                }
            });
            if (response.status === 200) {
                Swal.fire({
                    title: 'Sucesso!',
                    text: 'Form successfully submitted!',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                }).then(() => {
                    setFormData(initialFormData);
                    history.push('/Load');
                });
            }
        } catch (error) {
            console.error("Erro ao enviar o formulário:", error);
            Swal.fire({
                title: 'Erro!',
                text: 'Failed to submit the form. Please try again.',
                icon: 'error',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
            });
        } finally {
            setLoading(false);
        }
    };

    // Apply conditional styling
    const getFieldClass = (field) => {
        return highlightedFields.includes(field)
            ? 'border-red-500'
            : 'border-gray-300';
    };


    const fetchVehicleData = async (index, vin) => {
        try {
            setLoading(true);
            const userToken = localStorage.getItem('userToken');
            const response = await axios.get(`${API_PATH_PREFIX}/vin/${vin}`, {
                headers: {
                    "Screen-Name": "/NewLoad",
                    "Authorization": `Bearer ${userToken}`
                }
            });
            const { year, make, model } = response.data;
            const newCars = formData.cars.map((car, idx) => {
                if (idx === index) {
                    return { ...car, vin, year, make, model };
                }
                return car;
            });
            setFormData({ ...formData, cars: newCars });
        } catch (error) {
            console.error('Error fetching vehicle data:', error);
            Swal.fire({
                title: 'Error!',
                text: 'Failed to fetch vehicle data using VIN. Please try again later.',
                icon: 'error',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
            });
        } finally {
            setLoading(false);
        }
    };


    return (
        <div className="px-4">
            <div className="flex flex-col mx-auto mt-4 w-full max-w-lg bg-white shadow-md rounded-lg overflow-hidden">
                <div className="flex items-center p-4 bg-orange-500 text-white rounded-t-lg shadow-md">
                    <FaFilePdf className="w-8 h-8 text-white" />
                    <h2 className="flex-grow text-lg font-semibold text-center text-white" style={{ fontSize: '27px' }}>
                        Upload (PDF)
                    </h2>
                </div>

                {!pdfFile || uploadProgress === 0 ? (
                    <div
                        {...getRootProps()}
                        className={`flex flex-col items-center justify-center p-4 bg-gray-50 dark:bg-gray-900 transition-colors duration-200 rounded-b-lg shadow-lg border-2 border-dashed ${isDragActive ? 'border-blue-500' : 'border-gray-300'} w-11/12 mx-auto my-4`}
                    >
                        <input {...getInputProps()} className="hidden" />
                        <AiOutlineCloudUpload className="w-16 h-16 text-gray-400" />
                        <p className="mt-2 text-gray-600"><strong>Drop Dispatch sheet here</strong></p>
                        <p className="mt-1 text-sm text-gray-500">Click to upload or drag and drop your document here<strong> (Max 4 MB)</strong></p>
                        <p className="mt-1 text-sm text-gray-500">Supported companies:</p>
                        <div className="flex items-center space-x-2">
                            <img src={centraldispatchLogo} alt="Central Dispatch" className="h-5" style={{ height: '1.65rem' }} />
                        </div>
                    </div>
                ) : null}

                {pdfFile && uploadProgress > 0 && (
                    <div className="p-4">
                        <ProgressBar progress={uploadProgress} />
                    </div>
                )}

                {pdfFile && uploadStatus && uploadProgress === 100 && (
                    <div className="p-6">
                        {uploadStatus === 'success' && (
                            <div className="flex items-center justify-between p-4 bg-green-100 rounded-lg shadow">
                                <span className="text-green-700">Arquivo enviado com sucesso</span>
                                <button
                                    onClick={() => {
                                        setPdfFile(null);
                                        setUploadProgress(0);
                                        setUploadStatus(null);
                                    }}
                                    className="text-green-500 hover:text-green-700"
                                >
                                    Limpar
                                </button>
                            </div>
                        )}
                        {uploadStatus === 'error' && (
                            <div className="flex items-center justify-between p-4 bg-red-100 rounded-lg shadow">
                                <span className="text-red-700">Falha no upload</span>
                                <button
                                    onClick={() => {
                                        setPdfFile(null);
                                        setUploadProgress(0);
                                        setUploadStatus(null);
                                    }}
                                    className="text-red-500 hover:text-red-700"
                                >
                                    Limpar
                                </button>
                            </div>
                        )}
                    </div>
                )}
            </div>

            {loading ? (
                <Spinner />
            ) : (
                <form className="flex flex-col" id="mainForm" onSubmit={handleSubmit}>
                    <div className="flex flex-wrap justify-between">
                        {/* Card 1 */}
                        <Card className="mt-8 mx-auto bg-white shadow-lg rounded-lg overflow-hidden max-w-3xl">
                            <div
                                className="flex justify-between items-center p-4 bg-orange-500 text-white cursor-pointer" style={{ borderRadius: '10px' }}
                                onClick={() => toggleCard('card3')}
                            >
                                <FaInfoCircle className="w-8 h-8 text-white" />
                                <h2 className="text-lg font-semibold" style={{ fontSize: '27px' }}>
                                    General Information
                                </h2>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className={`transition-transform duration-300 ${isOpen.card3 ? 'rotate-180' : 'rotate-0'}`}
                                >
                                    <path d="M18 15l-6-6-6 6" />
                                </svg>

                            </div>

                            {/* {isOpen.card3 && ( */}
                            <div className="flex flex-col w-full p-6 rounded-b-lg">
                                <div className="flex flex-wrap -mx-3 mb-6">
                                    {/* Order Id */}
                                    <div className="flex flex-col px-2 w-full md:w-1/2 lg:w-1/3">
                                        <label
                                            htmlFor="orderId"
                                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                        >
                                            Order Id:
                                        </label>
                                        <input
                                            id="orderId"
                                            name="orderId"
                                            type="text"
                                            value={formData.orderId || ''}
                                            onChange={handleChange}
                                            className={`p-2.5 w-full text-sm text-gray-900 bg-white border ${getFieldClass('orderId')} rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white`}
                                            placeholder="Enter Order Id"
                                        />
                                        {highlightedFields.includes('orderId') && (
                                            <span className="text-red-500 text-xs italic">This field is mandatory!</span>
                                        )}
                                    </div>

                                    {/* Loadboard */}
                                    <div className="w-full lg:w-1/3 px-3 mb-4">
                                        <label
                                            htmlFor="loadboard"
                                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                        >
                                            Loadboard:
                                        </label>
                                        <select
                                            name="loadboard"
                                            value={formData.loadboard}
                                            onChange={handleChange}
                                            className="block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                        >
                                            <option value="" className="italic text-gray-400">
                                                Select LoadBoard
                                            </option>
                                            {/* Options */}
                                            {[
                                                'Cajuu',
                                                'Central dispatch',
                                                'Super dispatch',
                                                'Ship.cars',
                                                'Autosled',
                                                'Acertus',
                                                'Runbugy',
                                                'Ready',
                                                'Carsarrive',
                                                'United',
                                                'ACV Transportation',
                                                'Uship',
                                                'Email',
                                            ].map((option) => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    {/* Driver */}
                                    <div className="flex flex-col px-2 w-full md:w-1/2 lg:w-1/3">
                                        <label
                                            htmlFor="driver"
                                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                        >
                                            Driver:
                                        </label>
                                        <select
                                            name="driver"
                                            value={formData.driver}
                                            onChange={handleChange}
                                            className="block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                        >
                                            <option value="">
                                                No driver assigned
                                            </option>
                                            {driver?.map((driver) => (
                                                <option key={driver.id} value={driver.id}>
                                                    {driver.user
                                                        ? `${driver.user.first_name} ${driver.user.last_name}`
                                                        : 'Unknown Driver'}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    {/* Shipper Search */}
                                    <div className="flex flex-col px-2 w-full md:w-1/2 lg:w-1/3 mt-16">
                                        <label
                                            htmlFor="shipperSearch"
                                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                        >
                                            Shipper:
                                        </label>
                                        <div className="flex items-center">
                                            <input
                                                id="shipperSearch"
                                                name="shipperSearch"
                                                type="text"
                                                value={shipperSearch}
                                                onChange={handleChange}
                                                onKeyPress={async (event) => {
                                                    if (event.key === 'Enter') {
                                                        event.preventDefault();  // Prevent form submission
                                                        if (shipperSearch) {
                                                            const exists = await checkShipperExists(shipperSearch);
                                                            if (exists) {
                                                                setShipperExists(true);
                                                                setShipperMessage(`The shipper "${shipperSearch}" already exists in the system.`);
                                                            } else {
                                                                setShipperExists(false);
                                                                setShipperMessage(`The shipper "${shipperSearch}" was not found. You can create a new one.`);
                                                            }
                                                        }
                                                    }
                                                }}
                                                className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                placeholder="Search Shipper"
                                            />
                                            <button
                                                type="button"
                                                className="ml-2 text-base font-semibold text-white"
                                                onClick={() => setShowAddShipperForm(!showAddShipperForm)}  // Toggle the "Add Shipper" form visibility
                                            >
                                                <IoMdAddCircle className="text-black w-6 h-6" />
                                            </button>
                                        </div>

                                        {/* Display the search result or error message */}
                                        {shipperMessage && (
                                            <div className={`mt-2 text-sm ${shipperExists ? 'text-green-600' : 'text-red-600'}`}>
                                                {shipperMessage}
                                            </div>
                                        )}

                                        {/* Display the filtered shippers list */}
                                        {shipperSearch && filteredShippers.length > 0 && (
                                            <div className="bg-white border border-gray-300 mt-2 rounded-md shadow-lg">
                                                {filteredShippers.map((shipper) => (
                                                    <div
                                                        key={shipper.id}
                                                        onClick={() => {
                                                            setFormData({ ...formData, shipper: shipper.id });  // Set selected shipper's ID in form data
                                                            setShipperSearch(shipper.shipper_name);  // Set the input field to the selected shipper's name
                                                            setFilteredShippers([]);  // Clear the filtered list after selection
                                                        }}
                                                        className="cursor-pointer p-2 hover:bg-gray-200"
                                                    >
                                                        {shipper.shipper_name}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* )} */}
                        </Card>

                        {showAddShipperForm && (
                            <Card className="mt-8 mx-auto bg-white shadow-lg rounded-lg overflow-hidden max-w-3xl">
                                <div className="flex flex-col" id="shipperForm">
                                    <div className="flex w-full flex-col p-10 border-1">
                                        <div className="mt-5">
                                            <div className="w-full md:w-1/2 px-2 mb-4">
                                                <label htmlFor="shipperType" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                >Type :</label>
                                                <select
                                                    id="shipperType"
                                                    name="shipperType"
                                                    className="rounded-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    onChange={(e) => setNewShipperData({ ...newShipperData, shipperType: e.target.value })}

                                                >
                                                    <option value="">Select Type</option>
                                                    <option value="Shipper Company">Shipper Company</option>
                                                    <option value="Shipper User">Shipper User</option>
                                                </select>
                                            </div>

                                            <div className="flex flex-wrap -mx-2">
                                                {/* CAMPO SHIPPER NAME */}
                                                <div className="w-full md:w-1/2 px-2 mb-4">
                                                    <label htmlFor="shippername" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                    >Shipper Name :</label>
                                                    <div className="flex">
                                                        <input
                                                            type="text"
                                                            id="shipperName"
                                                            value={newShipperData.shipperName}
                                                            onChange={(e) => setNewShipperData({ ...newShipperData, shipperName: e.target.value })}
                                                            className="rounded-lg rounded-e-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                            placeholder="Enter Shipper Name"
                                                        />
                                                    </div>
                                                </div>

                                                {/* CAMPO PHONE */}
                                                <div className="w-full md:w-1/2 px-2 mb-4">
                                                    <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                    >Phone :</label>
                                                    <div className="flex">

                                                        <input
                                                            type="tel"
                                                            id="phone"
                                                            name="phone"
                                                            onChange={(e) => setNewShipperData({ ...newShipperData, shipperPhone: e.target.value })}
                                                            className="rounded-lg rounded-e-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                            placeholder="123-456-7890"
                                                        />
                                                    </div>
                                                </div>

                                                {/* CAMPO ACCOUNTING E-MAIL */}
                                                <div className="w-full md:w-1/2 px-2 mb-4">
                                                    <label htmlFor="accountingemail" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                    >Accounting E-mail :</label>
                                                    <div className="flex">

                                                        <input
                                                            type="email"
                                                            id="accountingEmail"
                                                            name="accountingEmail"
                                                            onChange={(e) => setNewShipperData({ ...newShipperData, accountingEmail: e.target.value })}
                                                            className="rounded-lg rounded-e-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                            placeholder="Enter Accounting E-mail"
                                                        />
                                                    </div>
                                                </div>

                                                {/* CAMPO INFO E-MAIL */}
                                                <div className="w-full md:w-1/2 px-2 mb-4">
                                                    <label htmlFor="infoEmail" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                    >Info E-mail :</label>
                                                    <div className="flex">

                                                        <input
                                                            type="email"
                                                            id="infoEmail"
                                                            name="infoEmail"
                                                            onChange={(e) => setNewShipperData({ ...newShipperData, infoEmail: e.target.value })}
                                                            className="rounded-lg rounded-e-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                            placeholder="Enter Info E-mail"
                                                        />
                                                    </div>
                                                </div>
                                                {/* CAMPO ADDRESS */}
                                                <div className="w-full md:w-1/2 px-2 mb-4">
                                                    <label htmlFor="address" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                    >Address :</label>
                                                    <div className="flex">
                                                        <input
                                                            style={{ width: '18rem' }}
                                                            type="text"
                                                            id="address"
                                                            name="address"
                                                            value={newShipperData.address || ''} // Ensure the input is controlled by newShipperData
                                                            onChange={(e) => setNewShipperData({ ...newShipperData, address: e.target.value })}
                                                            className="rounded-lg rounded-e-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                            placeholder="Enter Address"
                                                        />
                                                    </div>
                                                </div>


                                                {/* CAMPO CITY */}
                                                <div className="w-full md:w-1/2 px-2 mb-4">
                                                    <label htmlFor="city" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                    >
                                                        City :</label>
                                                    <div className="flex">

                                                        <input
                                                            style={{ width: '18rem' }}
                                                            type="text"
                                                            id="city"
                                                            name="city"
                                                            onChange={(e) => setNewShipperData({ ...newShipperData, city: e.target.value })}
                                                            className="rounded-lg rounded-e-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                            placeholder="Enter City"
                                                        />
                                                    </div>
                                                </div>

                                                {/* CAMPO STATE */}
                                                <div className="w-full md:w-1/2 px-2 mb-4">
                                                    <label htmlFor="state" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                    >State :</label>
                                                    <div className="flex">

                                                        <input
                                                            style={{ width: '18rem' }}
                                                            type="text"
                                                            id="state"
                                                            name="state"
                                                            onChange={(e) => setNewShipperData({ ...newShipperData, state: e.target.value })}
                                                            className="rounded-lg rounded-e-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                            placeholder="Enter State"
                                                        />
                                                    </div>
                                                </div>

                                                {/* CAMPO ZIPCODE */}
                                                <div className="w-full md:w-1/2 px-2 mb-4">
                                                    <label htmlFor="zipcode" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                    >Zipcode :</label>
                                                    <div className="flex">

                                                        <input
                                                            style={{ width: '18rem' }}
                                                            type="number"
                                                            id="zipcode"
                                                            name="zipcode"
                                                            onChange={(e) => setNewShipperData({ ...newShipperData, zipcode: e.target.value })}
                                                            className="rounded-lg rounded-e-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                            placeholder="Enter Zipcode"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex justify-end mt-5">
                                                <Button onClick={handleAddShipper}
                                                    className="mx-2 mr-2" style={{ backgroundColor: 'orange', marginTop: '0rem' }}
                                                >
                                                    Add Shipper
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div >
                            </Card>
                        )}


                        {/* Card 2 */}
                        <Card className="mt-8 mx-auto bg-white shadow-lg rounded-lg overflow-hidden max-w-3xl">
                            <div
                                className="flex justify-between items-center p-6 bg-orange-500 text-white cursor-pointer" style={{ borderRadius: '10px' }}
                                onClick={() => toggleCard('card2')}
                            >
                                <MdOutlinePayments className="w-8 h-8 text-white" />
                                <h2 className="text-lg font-semibold" style={{ fontSize: '27px' }}>
                                    Payment Info
                                </h2>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className={`transition-transform duration-300 ${isOpen.card2 ? 'rotate-180' : 'rotate-0'}`}
                                >
                                    <path d="M18 15l-6-6-6 6" />
                                </svg>
                            </div>

                            {/* {isOpen.card2 && ( */}
                            <div className="flex flex-wrap w-full p-6 rounded-b-lg">
                                {/* Payment Rate */}
                                <div className="w-full lg:w-1/3 px-3 mb-4">
                                    <label
                                        htmlFor="paymentRate"
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    >
                                        Payment Rate
                                    </label>
                                    <input
                                        id="paymentRate"
                                        name="paymentRate"
                                        type="number"
                                        value={formData.paymentRate || ''}
                                        onChange={handleChange}
                                        className={`p-2.5 w-full text-sm text-gray-900 bg-white border ${getFieldClass('paymentRate')} rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white`}
                                        placeholder="Enter Payment"
                                    />
                                    {highlightedFields.includes('paymentRate') && (
                                        <span className="text-red-500 text-xs italic">This field is mandatory!</span>
                                    )}
                                </div>

                                {/* Payment Type */}
                                <div className="w-full lg:w-1/3 px-3 mb-4">
                                    <label
                                        htmlFor="paymentType"
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    >
                                        Payment Type
                                    </label>
                                    <select
                                        id="paymentType"
                                        name="paymentType"
                                        value={formData.paymentType}
                                        onChange={handleChange}
                                        className="block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                    >
                                        <option value="" disabled className="italic text-gray-400">
                                            Select payment type
                                        </option>
                                        <option value="COD">COD - Cash on delivery</option>
                                        <option value="COP">COP - Cash on pickup</option>
                                        <option value="2 BD">2 BD</option>
                                        <option value="5 BD">5 BD</option>
                                        <option value="7 BD">7 BD</option>
                                        <option value="15 BD">15 BD</option>
                                        <option value="30 BD">30 BD</option>
                                    </select>
                                </div>

                                {/* Payment Method */}
                                <div className="w-full lg:w-1/3 px-3 mb-4">
                                    <label
                                        htmlFor="paymentMethod"
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    >
                                        Payment Method
                                    </label>
                                    <select
                                        id="paymentMethod"
                                        name="paymentMethod"
                                        value={formData.paymentMethod}
                                        onChange={handleChange}
                                        className="block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                    >
                                        <option value="" className="italic text-gray-400">
                                            Select payment method
                                        </option>
                                        <option value="ACH">ACH</option>
                                        <option value="Check">Check</option>
                                        <option value="Zelle">Zelle</option>
                                        <option value="Venmo">Venmo</option>
                                        <option value="Cashapp">Cashapp</option>
                                        <option value="Uship">Uship</option>
                                        <option value="Cash">Cash</option>
                                        <option value="PayPal">PayPal</option>
                                        <option value="Credit card">Credit card</option>
                                        <option value="Debit">Debit</option>
                                        <option value="Comcheck">Comcheck</option>
                                        <option value="Echeck">Echeck</option>
                                        <option value="Eletronic Check">Eletronic Check</option>
                                    </select>
                                </div>
                            </div>
                            {/* )} */}
                        </Card>

                    </div>

                    {/* COMPONENTE "Payment Info" modificado para "Load Items Info" */}
                    <div className="flex flex-wrap justify-between">
                        {/* Card para Load Items Info */}
                        <Card className="mt-8 mx-auto bg-white shadow-lg rounded-lg overflow-hidden max-w-3xl">
                            <div
                                className="flex justify-between items-center p-6 bg-orange-500 text-white cursor-pointer" style={{ borderRadius: '10px' }}
                                onClick={() => toggleCard('card3')}
                            >
                                <FaInfoCircle className="w-8 h-8 text-white" />
                                <h2 className="text-lg font-semibold" style={{ fontSize: '27px' }}>
                                    Load Items Info
                                </h2>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={`transition-transform duration-300 ${isOpen.card2 ? 'rotate-180' : 'rotate-0'}`}>
                                    <path d="M18 15l-6-6-6 6" />
                                </svg>
                            </div>

                            <div className="flex flex-col w-full p-6 rounded-b-lg">
                                {formData.cars.map((car, index) => (
                                    <div key={index} className="flex flex-wrap -mx-3 mb-6">
                                        {/* VIN Field */}
                                        <div className="flex flex-col px-2 w-full md:w-1/2 lg:w-1/3">
                                            <label htmlFor="vin" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                Vin:
                                            </label>
                                            <input
                                                name="vin"
                                                value={car.vin}
                                                onChange={(e) => handleVinChange(index, e)}
                                                placeholder="Vin Number Car"
                                                className="p-2.5 w-full text-sm text-gray-900 bg-white border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                            />
                                        </div>

                                        {/* Year Field */}
                                        <div className="flex flex-col px-2 w-full md:w-1/2 lg:w-1/3">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                Year:
                                            </label>
                                            <input
                                                name="year"
                                                value={car.year || ''}
                                                onChange={(e) => handleCarChange(index, e)}
                                                placeholder="Year Car"
                                                type="number"
                                                className={`p-2.5 w-full text-sm text-gray-900 bg-white border ${getFieldClass(`car-${index}-year`)} rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white`}
                                            />
                                            {highlightedFields.includes(`car-${index}-year`) && (
                                                <span className="text-red-500 text-xs italic">This field is mandatory!</span>
                                            )}
                                        </div>


                                        {/* Make Field */}
                                        <div className="flex flex-col px-2 w-full md:w-1/2 lg:w-1/3">
                                            <label htmlFor="make" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                Make:
                                            </label>
                                            <input
                                                name="make"
                                                value={car.make || ''}
                                                onChange={(e) => handleCarChange(index, e)}
                                                placeholder="Make Car"
                                                type="text"
                                                className={`p-2.5 w-full text-sm text-gray-900 bg-white border ${getFieldClass(`car-${index}-make`)} rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white`}
                                            />
                                            {highlightedFields.includes(`car-${index}-make`) && (
                                                <span className="text-red-500 text-xs italic">This field is mandatory!</span>
                                            )}
                                        </div>

                                        {/* Model Field */}
                                        <div className="flex flex-col px-2 w-full md:w-1/2 lg:w-1/3">
                                            <label htmlFor="model" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                Model:
                                            </label>
                                            <input
                                                name="model"
                                                value={car.model || ''}
                                                onChange={(e) => handleCarChange(index, e)}
                                                placeholder="Model Car"
                                                type="text"
                                                className={`p-2.5 w-full text-sm text-gray-900 bg-white border ${getFieldClass(`car-${index}-model`)} rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white`}
                                            />
                                            {highlightedFields.includes(`car-${index}-model`) && (
                                                <span className="text-red-500 text-xs italic">This field is mandatory!</span>
                                            )}
                                        </div>

                                        {/* CarType Field */}
                                        <div className="flex flex-col px-2 w-full md:w-1/2 lg:w-1/3">
                                            <label htmlFor="carType" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                Car Type:
                                            </label>
                                            <select
                                                name="carType"
                                                value={car.carType || ''}
                                                onChange={(e) => handleCarChange(index, e)}
                                                className={`p-2.5 w-full text-sm text-gray-900 bg-white border ${getFieldClass(`car-${index}-carType`)} rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white`}
                                            >
                                                <option value="" disabled>Select Car Type</option>
                                                {carTypes.map((cartype) => (
                                                    <option key={cartype.id} value={cartype.id}>
                                                        {cartype.type}
                                                    </option>
                                                ))}
                                            </select>
                                            {highlightedFields.includes('car.carType') && (
                                                <span className="text-red-500 text-xs italic">This field is mandatory!</span>
                                            )}
                                        </div>

                                        {/* Lot Number Field */}
                                        <div className="flex flex-col px-2 w-full md:w-1/2 lg:w-1/3">
                                            <label htmlFor="lotNumber" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                Lot N.:
                                            </label>
                                            <input
                                                name="lotNumber"
                                                value={car.lotNumber || ''}
                                                onChange={(e) => handleCarChange(index, e)}
                                                placeholder="Lot Number"
                                                className="p-2.5 w-full text-sm text-gray-900 bg-white border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                            />
                                        </div>

                                        {/* Buyer Number Field */}
                                        <div className="flex flex-col px-2 w-full md:w-1/2 lg:w-1/3">
                                            <label htmlFor="buyerNumber" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                Buyer N.:
                                            </label>
                                            <input
                                                name="buyerNumber"
                                                value={car.buyerNumber || ''}
                                                onChange={(e) => handleCarChange(index, e)}
                                                placeholder="Buyer Number"
                                                className="p-2.5 w-full text-sm text-gray-900 bg-white border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                            />
                                        </div>

                                        {/* Operability Field */}
                                        <div className="flex flex-col px-2 w-full md:w-1/2 lg:w-1/3">
                                            <label htmlFor="isOperable" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                Is It Operable:
                                            </label>
                                            <select
                                                name="isOperable"
                                                value={car.isOperable || ''}
                                                onChange={(e) => handleCarChange(index, e)}
                                                className={`p-2.5 w-full text-sm text-gray-900 bg-white border ${getFieldClass(`car-${index}-isOperable`)} rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white`}
                                            >
                                                <option value="">Select Is Operable</option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                            {highlightedFields.includes(`car-${index}-isOperable`) && (
                                                <span className="text-red-500 text-xs italic">This field is mandatory!</span>
                                            )}
                                        </div>
                                    </div>
                                ))}
                                {/* <div className="mt-4 flex justify-end">
                                    {formData.cars.length > 1 && (
                                        <button className="flex items-center justify-center p-2 text-red-600 hover:text-red-800 dark:hover:text-red-400 transition-colors duration-200">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                <path d="M3 6h18M9 9v10M15 9v10M10 3h4a1 1 0 011 1v3H9V4a1 1 0 011-1zM6 6h12v14a1 1 0 01-1 1H7a1 1 0 01-1-1V6z" />
                                            </svg>
                                        </button>
                                        // onClick={() => deleteCar(index)}
                                    )}
                                </div> */}
                                <div className="flex justify-end mt-4 space-x-4">
                                    <button
                                        type="button"
                                        className="flex items-center p-2 text-orange-600 hover:text-orange-800 transition-colors duration-200"
                                        onClick={addCar}
                                        disabled={formData.cars.length >= 9}
                                    >
                                        <IoMdAddCircle className="mr-2" /> Add Car
                                    </button>
                                    {showRemoveCarButton && (
                                        <button
                                            type="button"
                                            className="flex items-center p-2 text-red-600 hover:text-red-800 transition-colors duration-200"
                                            onClick={deleteLastCar}
                                            disabled={formData.cars.length <= 1}
                                        >
                                            <GrPowerReset className="mr-2" /> Remove Last Car
                                        </button>
                                    )}
                                </div>


                            </div>
                        </Card>
                    </div>




                    <div className="flex flex-wrap justify-between">
                        {/* Card 1 */}
                        {/* COMPONENTE OR Pickup */}
                        <Card className="mt-8 mx-auto bg-white shadow-lg rounded-lg overflow-hidden max-w-3xl">
                            <div
                                className="flex justify-between items-center p-6 bg-orange-500 text-white cursor-pointer" style={{ borderRadius: '10px' }}
                                onClick={() => toggleCard('card4')}
                            >
                                <MdOutlinePayments className="w-8 h-8 text-white" />
                                <h2 className="text-lg font-semibold" style={{ fontSize: '27px' }}>
                                    pickup Information
                                </h2>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className={`transition-transform duration-300 ${isOpen.card4 ? 'rotate-180' : 'rotate-0'}`}
                                >
                                    <path d="M18 15l-6-6-6 6" />
                                </svg>
                            </div>

                            <div className="flex flex-wrap w-full p-6 rounded-b-lg">
                                {/* Contact Company */}
                                <div className="w-full lg:w-1/3 px-3 mb-4">
                                    <label
                                        htmlFor="PickupBusinessName"
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    >
                                        Contact Company
                                    </label>
                                    <input
                                        id="PickupBusinessName"
                                        name="PickupBusinessName"
                                        type="text"
                                        value={formData.PickupBusinessName}
                                        onChange={(e) => setFormData({ ...formData, PickupBusinessName: e.target.value })}
                                        className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                        placeholder="Enter Contact Company"
                                    />
                                </div>

                                {/* Contact Name */}
                                <div className="w-full lg:w-1/3 px-3 mb-4">
                                    <label
                                        htmlFor="contactNamepickup"
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    >
                                        Contact Name
                                    </label>
                                    <input
                                        id="contactNamepickup"
                                        name="contactNamepickup"
                                        type="text"
                                        value={formData.contactNamepickup}
                                        onChange={(e) => setFormData({ ...formData, contactNamepickup: e.target.value })}
                                        className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                        placeholder="Enter Contact Name"
                                    />
                                </div>

                                {/* Delivery Date Estimated */}
                                <div className="w-full lg:w-1/3 px-3 mb-4">
                                    <label
                                        htmlFor="pickupDateEstimated"
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    >
                                        Delivery Date Estimated
                                    </label>
                                    <input
                                        id="pickupDateEstimated"
                                        name="pickupDateEstimated"
                                        type="date"
                                        value={formData.pickupDateEstimated}
                                        onChange={handleChange}
                                        className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                    />
                                </div>

                                {/* Email */}
                                <div className="w-full lg:w-1/3 px-3 mb-4">
                                    <label
                                        htmlFor="emailpickup"
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    >
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        name="emailpickup"
                                        id="emailpickup"
                                        value={formData.emailpickup || ''}
                                        onChange={handleChange}
                                        className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                        placeholder="Cajuu@email.com"
                                    />
                                </div>

                                {/* Phone */}
                                <div className="w-full lg:w-1/3 px-3 mb-4">
                                    <label
                                        htmlFor="phonepickup"
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    >
                                        Phone
                                    </label>
                                    <input
                                        type="tel"
                                        name="phonepickup"
                                        id="phonepickup"
                                        value={formData.phonepickup || ''}
                                        onChange={handleChange}
                                        className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                        placeholder="123-456-7890"
                                    />
                                </div>

                                {/* Notes */}
                                <div className="flex flex-col px-2 w-full">
                                    <label htmlFor="NotesPickup" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                        Notes:
                                    </label>
                                    <textarea
                                        id="NotesPickup"
                                        className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                        rows="5"
                                        placeholder="Enter your observations here..."
                                    ></textarea>
                                </div>

                                {/* Address */}
                                <div className="w-full lg:w-1/3 px-3 mb-4">
                                    <label
                                        htmlFor="addresspickup"
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    >
                                        Address
                                    </label>
                                    <input
                                        type="text"
                                        name="addresspickup"
                                        id="addresspickup"
                                        value={formData.addresspickup || ''}
                                        onChange={handleChange}
                                        className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                        placeholder="Enter the Address"
                                    />
                                    {highlightedFields.includes('addresspickup') && (
                                        <span className="text-red-500 text-xs italic">This field is mandatory!</span>
                                    )}
                                </div>

                                {/* City */}
                                <div className="w-full lg:w-1/3 px-3 mb-4">
                                    <label
                                        htmlFor="citypickup"
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    >
                                        City
                                    </label>
                                    <input
                                        type="text"
                                        name="citypickup"
                                        id="citypickup"
                                        value={formData.citypickup || ''}
                                        onChange={handleChange}
                                        className={`p-2.5 w-full text-sm text-gray-900 bg-white border ${getFieldClass('citypickup')} rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white`}
                                        placeholder="Enter the City"
                                    />
                                    {highlightedFields.includes('citypickup') && (
                                        <span className="text-red-500 text-xs italic">This field is mandatory!</span>
                                    )}
                                </div>

                                {/* State */}
                                <div className="w-full lg:w-1/3 px-3 mb-4">
                                    <label
                                        htmlFor="Statepickup"
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    >
                                        State
                                    </label>
                                    <input
                                        type="text"
                                        name="Statepickup"
                                        id="Statepickup"
                                        value={formData.Statepickup || ''}
                                        onChange={handleChange}
                                        className={`p-2.5 w-full text-sm text-gray-900 bg-white border ${getFieldClass('Statepickup')} rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white`}
                                        placeholder="Enter the State"
                                    />
                                    {highlightedFields.includes('Statepickup') && (
                                        <span className="text-red-500 text-xs italic">This field is mandatory!</span>
                                    )}
                                </div>

                                {/* Zipcode */}
                                <div className="w-full lg:w-1/3 px-3 mb-4">
                                    <label
                                        htmlFor="zipcodepickup"
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    >
                                        Zipcode
                                    </label>
                                    <input
                                        type="text"
                                        name="zipcodepickup"
                                        id="zipcodepickup"
                                        value={formData.zipcodepickup || ''}
                                        onChange={handleChange}
                                        className={`p-2.5 w-full text-sm text-gray-900 bg-white border ${getFieldClass('zipcodepickup')} rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white`}
                                        placeholder="Enter the Zipcode"
                                    />
                                    {highlightedFields.includes('zipcodepickup') && (
                                        <span className="text-red-500 text-xs italic">This field is mandatory!</span>
                                    )}
                                </div>
                            </div>
                        </Card>



                        {/* COMPONENTE OR DELIVERY */}
                        <Card className="mt-8 mx-auto bg-white shadow-lg rounded-lg overflow-hidden max-w-3xl">
                            <div
                                className="flex justify-between items-center p-6 bg-orange-500 text-white cursor-pointer" style={{ borderRadius: '10px' }}
                                onClick={() => toggleCard('card4')}
                            >
                                <MdOutlinePayments className="w-8 h-8 text-white" />
                                <h2 className="text-lg font-semibold" style={{ fontSize: '27px' }}>
                                    Delivery Information
                                </h2>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className={`transition-transform duration-300 ${isOpen.card4 ? 'rotate-180' : 'rotate-0'}`}
                                >
                                    <path d="M18 15l-6-6-6 6" />
                                </svg>
                            </div>

                            <div className="flex flex-wrap w-full p-6 rounded-b-lg">
                                {/* Contact Company */}
                                <div className="w-full lg:w-1/3 px-3 mb-4">
                                    <label
                                        htmlFor="DeliveryBusinessName"
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    >
                                        Contact Company
                                    </label>
                                    <input
                                        id="DeliveryBusinessName"
                                        name="DeliveryBusinessName"
                                        type="text"
                                        value={formData.DeliveryBusinessName}
                                        onChange={(e) => setFormData({ ...formData, DeliveryBusinessName: e.target.value })}
                                        className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                        placeholder="Enter Contact Company"
                                    />
                                </div>

                                {/* Contact Name */}
                                <div className="w-full lg:w-1/3 px-3 mb-4">
                                    <label
                                        htmlFor="contactNamedelivery"
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    >
                                        Contact Name
                                    </label>
                                    <input
                                        id="contactNamedelivery"
                                        name="contactNamedelivery"
                                        type="text"
                                        value={formData.contactNamedelivery}
                                        onChange={(e) => setFormData({ ...formData, contactNamedelivery: e.target.value })}
                                        className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                        placeholder="Enter Contact Name"
                                    />
                                </div>

                                {/* Delivery Date Estimated */}
                                <div className="w-full lg:w-1/3 px-3 mb-4">
                                    <label
                                        htmlFor="deliveryDateEstimated"
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    >
                                        Delivery Date Estimated
                                    </label>
                                    <input
                                        id="deliveryDateEstimated"
                                        name="deliveryDateEstimated"
                                        type="date"
                                        value={formData.deliveryDateEstimated}
                                        onChange={handleChange}
                                        className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                    />
                                </div>

                                {/* Email */}
                                <div className="w-full lg:w-1/3 px-3 mb-4">
                                    <label
                                        htmlFor="emaildelivery"
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    >
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        name="emaildelivery"
                                        id="emaildelivery"
                                        value={formData.emaildelivery || ''}
                                        onChange={handleChange}
                                        className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                        placeholder="Cajuu@email.com"
                                    />
                                </div>

                                {/* Phone */}
                                <div className="w-full lg:w-1/3 px-3 mb-4">
                                    <label
                                        htmlFor="phonedelivery"
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    >
                                        Phone
                                    </label>
                                    <input
                                        type="tel"
                                        name="phonedelivery"
                                        id="phonedelivery"
                                        value={formData.phonedelivery || ''}
                                        onChange={handleChange}
                                        className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                        placeholder="123-456-7890"
                                    />
                                </div>

                                {/* Notes */}
                                <div className="flex flex-col px-2 w-full">
                                    <label htmlFor="NoteDelivery" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                        Notes:
                                    </label>
                                    <textarea
                                        id="NoteDelivery"
                                        className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                        rows="5"
                                        placeholder="Enter your observations here..."
                                    ></textarea>
                                </div>

                                {/* Address */}
                                <div className="w-full lg:w-1/3 px-3 mb-4">
                                    <label
                                        htmlFor="addressdelivery"
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    >
                                        Address
                                    </label>
                                    <input
                                        type="text"
                                        name="addressdelivery"
                                        id="addressdelivery"
                                        value={formData.addressdelivery || ''}
                                        onChange={handleChange}
                                        className={`p-2.5 w-full text-sm text-gray-900 bg-white border ${getFieldClass('addressdelivery')} rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white`}
                                        placeholder="Enter the Address"
                                    />
                                    {highlightedFields.includes('addressdelivery') && (
                                        <span className="text-red-500 text-xs italic">This field is mandatory!</span>
                                    )}
                                </div>

                                {/* City */}
                                <div className="w-full lg:w-1/3 px-3 mb-4">
                                    <label
                                        htmlFor="citydelivery"
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    >
                                        City
                                    </label>
                                    <input
                                        type="text"
                                        name="citydelivery"
                                        id="citydelivery"
                                        value={formData.citydelivery || ''}
                                        onChange={handleChange}
                                        className={`p-2.5 w-full text-sm text-gray-900 bg-white border ${getFieldClass('citydelivery')} rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white`}
                                        placeholder="Enter the City"
                                    />
                                    {highlightedFields.includes('citydelivery') && (
                                        <span className="text-red-500 text-xs italic">This field is mandatory!</span>
                                    )}
                                </div>

                                {/* State */}
                                <div className="w-full lg:w-1/3 px-3 mb-4">
                                    <label
                                        htmlFor="Statedelivery"
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    >
                                        State
                                    </label>
                                    <input
                                        type="text"
                                        name="Statedelivery"
                                        id="Statedelivery"
                                        value={formData.Statedelivery || ''}
                                        onChange={handleChange}
                                        className={`p-2.5 w-full text-sm text-gray-900 bg-white border ${getFieldClass('Statedelivery')} rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white`}
                                        placeholder="Enter the State"
                                    />
                                    {highlightedFields.includes('Statedelivery') && (
                                        <span className="text-red-500 text-xs italic">This field is mandatory!</span>
                                    )}
                                </div>

                                {/* Zipcode */}
                                <div className="w-full lg:w-1/3 px-3 mb-4">
                                    <label
                                        htmlFor="zipcodedelivery"
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    >
                                        Zipcode
                                    </label>
                                    <input
                                        type="text"
                                        name="zipcodedelivery"
                                        id="zipcodedelivery"
                                        value={formData.zipcodedelivery || ''}
                                        onChange={handleChange}
                                        className={`p-2.5 w-full text-sm text-gray-900 bg-white border ${getFieldClass('zipcodedelivery')} rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white`}
                                        placeholder="Enter the Zipcode"
                                    />
                                    {highlightedFields.includes('zipcodedelivery') && (
                                        <span className="text-red-500 text-xs italic">This field is mandatory!</span>
                                    )}
                                </div>
                            </div>
                        </Card>


                    </div>

                    {/* Botões de Ação */}
                    <div className="flex justify-center mt-8">
                        <button
                            type="submit"
                            className="flex items-center justify-center py-3 px-8 rounded-lg text-white font-bold bg-orange-500 hover:bg-orange-600 transition-colors duration-300"
                        >
                            <RiSendPlaneFill className="mr-2" /> Enviar
                        </button>
                        <button
                            type="button"
                            className="flex items-center justify-center py-3 px-8 ml-4 rounded-lg text-white font-bold bg-red-500 hover:bg-red-600 transition-colors duration-300"
                            onClick={() => setFormData(initialFormData)}
                        >
                            <GrPowerReset className="mr-2" /> Resetar
                        </button>
                    </div>
                </form>
            )}
        </div>

    );
};

export default FormSubmitHandler;