import React, { useEffect, useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import Image from '@material-tailwind/react/Image';
import {jwtDecode} from 'jwt-decode';
import './css/Sidebar.css';
import AdminNavbar from './AdminNavbar';
import { useSwipeable } from 'react-swipeable';
import { MdEditDocument } from "react-icons/md";
import { TfiDashboard } from "react-icons/tfi";
import { VscNewFile } from "react-icons/vsc";
import { FaTruckFront } from "react-icons/fa6";
import { FaUserPlus } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";
import useHomePage from '../hooks/UseHomePage';
import ProfilePicture from 'assets/img/LOGO CAJU.png';
import { BsTruckFlatbed } from "react-icons/bs";
import { IoMdAdd } from "react-icons/io";
import { IoPersonAdd } from "react-icons/io5";
import { SlEnvolopeLetter } from "react-icons/sl";

export default function Sidebar() {
    const [showSidebar, setShowSidebar] = useState(false);
    const [showSubItems, setShowSubItems] = useState({ Quotes: false, Load: false });
    const [userDetails, setUserDetails] = useState({ name: '', email: '', picture: '', user_role: '' });
    const homePage = useHomePage();
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        const getUserDetailsFromToken = () => {
            const token = localStorage.getItem('userToken');
            if (token) {
                const decoded = jwtDecode(token);
                setUserDetails({
                    name: decoded.first_name + " " + decoded.last_name,
                    email: decoded.email,
                    user_role: decoded.user_role_name,
                    picture: decoded.profile_picture_path,
                });
                console.log(decoded.user_role_name); // Verifique o valor do user_role
            }
        };
    
        getUserDetailsFromToken();
    }, []);
    

    const handleToggleSidebar = () => {
        setShowSidebar(!showSidebar);
    };

    const handlers = useSwipeable({
        onSwipedLeft: () => setShowSidebar(false),
        onSwipedRight: () => setShowSidebar(true),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    const handleNavLinkClick = (to) => {
        history.push(to);
        setShowSidebar(false); // Opcional: fecha a sidebar ao clicar no link
    };

    const tabs = {
        Admin: [
            { to: homePage, icon: <TfiDashboard className="w-6 h-6" />, label: "Dashboard" },
            {
                to: "/Load",
                label: "Loads",
                icon: <FaTruckFront className="w-6 h-6" />,
                subItems: [
                    { to: "/NewLoad", label: "New Load", icon: <IoMdAdd className="w-6 h-6" /> }
                ]
            },
            {
                to: "/NewQuotes",
                label: "Quotes",
                icon: <MdEditDocument className="w-6 h-6" />,
                subItems: [
                    { to: "/Quotes", label: "New Quote", icon: <VscNewFile className="w-6 h-6" />, }
                ]
            },
            { to: "/StaffInvitationCarrier", icon: <SlEnvolopeLetter className="w-6 h-6" />, label: "Staff Invitation" },
            { to: "/EmployeeRegistration", icon: <IoPersonAdd  className="w-6 h-6"/>, label:"Employee Registration" },
        ],
        'Carrier Company Owner': [
            { to: homePage, icon: <TfiDashboard className="w-6 h-6" />, label: "Dashboard" },
            {
                to: "/Load",
                label: "Loads",
                icon: <FaTruckFront className="w-6 h-6" />,
                subItems: [
                    { to: "/NewLoad", label: "New Load", icon: <IoMdAdd className="w-6 h-6" /> }
                ]
            },
            {
                to: "/NewQuotes",
                label: "Quotes",
                icon: <MdEditDocument className="w-6 h-6" />,
                subItems: [
                    { to: "/Quotes", label: "New Quote", icon: <VscNewFile className="w-6 h-6" />, }
                ]
            },
            { to: "/StaffInvitationCarrier", icon: <SlEnvolopeLetter className="w-6 h-6" />, label: "Staff Invitation" },
        
            { to: "/EmployeeRegistration", icon: <IoPersonAdd  className="w-6 h-6"/>, label:"Employee Registration" },
        ],
        Driver: [
            { to: homePage, icon: <TfiDashboard className="w-6 h-6" />, label: "Dashboard" },
            { to: "/Load", label: "Loads", icon: <FaTruckFront className="w-6 h-6" /> },
        ],
        Dispatcher: [
            { to: homePage, icon: <TfiDashboard className="w-6 h-6" />, label: "Dashboard" },
            {
                to: "/Load",
                label: "Loads",
                icon: <FaTruckFront className="w-6 h-6" />,
                subItems: [
                    { to: "/NewLoad", label: "New Load", icon: <IoMdAdd className="w-6 h-6" /> }
                ]
            },
            {
                to: "/NewQuotes",
                label: "Quotes",
                icon: <MdEditDocument className="w-6 h-6" />,
                subItems: [
                    { to: "/Quotes", label: "New Quote", icon: <VscNewFile className="w-6 h-6" />, }
                ]
            },
        ],
        Customer: [
            { to: homePage, icon: <TfiDashboard className="w-6 h-6" />, label: "Dashboard" },
            { to: "/Load", label: "Loads", icon: <FaTruckFront className="w-6 h-6" /> },
        ],
        Dealer: [
            { to: homePage, icon: <TfiDashboard className="w-6 h-6" />, label: "Dashboard" },
            {
                to: "/Load",
                label: "Loads",
                icon: <FaTruckFront className="w-6 h-6" />,
                subItems: [
                    { to: "/NewLoad", label: "New Load", icon: <IoMdAdd className="w-6 h-6" /> }
                ]
            },
            {
                to: "/NewQuotes",
                label: "Quotes",
                icon: <MdEditDocument className="w-6 h-6" />,
                subItems: [
                    { to: "/Quotes", label: "New Quote", icon: <VscNewFile className="w-6 h-6" />, }
                ]
            },
            // { to: "/StaffInvitation", icon: <FaUserPlus className="w-6 h-6" />, label: "Staff Invitation" },
        ],
    };

    const renderTabs = (tabs) => {
        if (!tabs) return null;
        return tabs.map((tab, index) => (
            <li key={index} className="rounded-lg mb-4">
                {tab.subItems ? (
                    <div>
                        <button
                            className={`flex flex-col items-center text-sm text-gray-700 font-light px-2 py-3 rounded-lg w-full text-center ${location.pathname === tab.to ? 'bg-gradient-to-tr from-orange-500 to-orange-700 text-white shadow-md' : ''
                                }`}
                            onClick={() => {
                                setShowSubItems({ ...showSubItems, [tab.label]: !showSubItems[tab.label] });
                                handleNavLinkClick(tab.to);
                            }}
                        >
                            {tab.icon}
                            <span className="mt-2">{tab.label}</span>
                            <FaChevronDown className={`transition-transform duration-200 ${showSubItems[tab.label] ? 'rotate-180' : 'rotate-0'}`} />
                        </button>
                        <ul className={`flex flex-col mt-2 ${showSubItems[tab.label] ? 'block' : 'hidden'}`}>
                            {tab.subItems.map((subItem, subIndex) => (
                                <li key={subIndex} className="rounded-lg mb-4">
                                    <NavLink
                                        to={subItem.to}
                                        exact
                                        className="flex flex-col items-center gap-y-2 text-sm text-gray-700 font-light px-2 py-3 rounded-lg focus:outline-none focus:border-2 focus:border-white"
                                        activeClassName="bg-gradient-to-tr from-orange-500 to-orange-700 text-white shadow-md"
                                    >
                                        {subItem.icon}
                                        <span className="mt-2">{subItem.label}</span>
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : (
                    <NavLink
                        to={tab.to}
                        exact
                        className="flex flex-col items-center text-sm text-gray-700 font-light px-2 py-3 rounded-lg focus:outline-none focus:border-2 focus:border-white"
                        activeClassName="bg-gradient-to-tr from-orange-500 to-orange-700 text-white shadow-md"
                        onClick={() => handleNavLinkClick(tab.to)}
                    >
                        {tab.icon}
                        <span className="mt-2">{tab.label}</span>
                    </NavLink>
                )}
            </li>
        ));
    };

    return (
        <>
            <AdminNavbar
                showSidebar={showSidebar}
                setShowSidebar={setShowSidebar}
            />
            <div
                {...handlers}
                className={`h-screen fixed top-0 md:left-0 ${showSidebar ? 'left-0' : '-left-64'
                    } overflow-y-auto flex-row flex-nowrap overflow-hidden shadow-xl bg-white w-32 transition-all duration-300 px-2 sidebar-menu`}
            >
                <div className="flex-col items-stretch min-h-full flex-nowrap px-0 relative overflow-hidden">
                    <div>
                        <Image src={ProfilePicture} className="w-20 mt-10 mx-auto my-auto rounded-none" />
                    </div>
                    <a
                        href={homePage}
                        rel="noreferrer"
                        className="mt-2 text-center w-full inline-block"
                    >
                    </a>
                    <div className="flex flex-col">
                        <hr className="my-4 min-w-full" />
                        <ul className="flex-col min-w-full flex list-none">
                            {renderTabs(tabs[userDetails.user_role])}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}
